import styled from 'styled-components';
import error from 'assets/Matriz/error.svg';

export const ContainerLabelInput = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
  margin-top: 8px;
`;

export const UnInputLabel = styled.label`
  color: #6d6d6d;
  margin-bottom: 8px;
  font-size: 15px;
  margin-top: 10px;
  display: ruby;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  ${({ zIndex }) => zIndex && `z-index: ${zIndex};`}
  position: relative;
  ${({ width }) => width && `width: ${width};`}
  ${({ margin }) => margin && `margin: ${margin};`}
`;

export const Error = styled.span`
  display: flex;
  align-items: center;
  gap: 12px;
  margin: 5px 0px;
  font-size: 12px;

  &:before {
    content: url(${error});
    width: 10px;
    height: 15px;
  }
`;
export const HintInformation = styled.div`
  width: 5px;
  height: 5px;
  border: 1px solid;
  margin-left: 5px;
  padding: 5px;
  border-radius: 50%;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-size: 8px;
`;
