import { useState, createContext, useContext } from 'react';
import AssociateTagUniversityForm from '../Screens/Forms/AssociateTagUniversityForm';

const FormContext = createContext();

export const FormProvider = ({ children }) => {
  const [currentFormToShow, setCurrentFormToShow] = useState(null);
  const [limit, setLimit] = useState(10);
  const [searchTeam, setSearchTeam] = useState('');
  const [queryFilter, setQueryFilter] = useState('');
  const [pendingTags, setPendingTags] = useState([]);
  const [associatedTags, setAssociatedTags] = useState([]);
  const [mainTags, setMainTags] = useState([]);
  const [viewAssociationTags, setViewAssociationTags] = useState([]);
  const [isListEmpty, setListEmpty] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [activeSubTab, setActiveSubTab] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);




  return (
    <FormContext.Provider
      value={{
        limit,
        setLimit,
        currentFormToShow,
        setCurrentFormToShow,
        searchTeam,
        setSearchTeam,
        queryFilter,
        setQueryFilter,
        pendingTags,
        setPendingTags,
        associatedTags,
        setAssociatedTags,
        mainTags,
        setMainTags,
        viewAssociationTags,
        setViewAssociationTags,
        isListEmpty,
        setListEmpty,
        isLoading,
        setIsLoading,
        activeSubTab,
        setActiveSubTab,
        currentPage,
        setCurrentPage,
      }}
    >
      {children}
    </FormContext.Provider>
  );
};

export function useFormContext() {
  return useContext(FormContext);
}
