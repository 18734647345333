import React, { Fragment } from 'react';
import ReactDOM from 'react-dom';
import { bool, func, shape } from 'prop-types';

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
import { Divider, Grid, GridList, GridListTile } from '@material-ui/core';
// Third party components
import StepWizard from 'react-step-wizard';

// core components
import Card from 'components/Card/Card';
import CardBody from 'components/Card/CardBody';
import RadioGroupWithButtons from 'components/RadioGroupWithButtons/RadioGroupWithButtons';
import behavioralTestStyle from 'assets/jss/material-dashboard-pro-react/views/behavioralTestStyle';

import { FinallyButtonTest, NextStepTest } from 'components/SaveButtonsTests/FinallyAndNextStep';
import { triggerHotjarEvent } from 'utils/hotjar';
import { Mixpanel } from 'utils/mixpanel';
import Nav from './PaginationBullets';
import { saveBehavioralTest } from '../../../../services/student/behavioralTest';
import { getBehavioralNextDate, getBehavioralTestQuestions } from '../../../../services/student/quizzes';
import { triggerPageView } from '../../../../utils/analytics';

import { FooterText } from './styles';
import { customSnackbar } from 'components/CustomSnackBar/customSnackbar';
import { actionsProfileStore } from 'stores/profile.store';

class BehavioralTest extends React.Component {
  state = {
    step: 0,
    questionsAnswers: {},
    questionsList: null,
  };
  wizard = React.createRef();

  wizardHolder = React.createRef();

  updateCard = actionsProfileStore.updateCard;

  async componentDidMount() {
    Mixpanel.track('Abriu Teste Comportamental');
    await this.getBehavioralTestQuestionsHandle();
    if (this.props.metadata?.university_slug !== 'uniexemplo') {
      triggerHotjarEvent('behavioral');
    }
  }

  handleChangeRadio = (event) => {
    const { questionsAnswers } = this.state;

    this.setState({
      questionsAnswers: {
        ...questionsAnswers,
        [event.target.name]: event.target.value,
      },
    });
    const elementToScroll = event.target.closest('li').nextSibling;
    if (elementToScroll) {
      ReactDOM.findDOMNode(elementToScroll).scrollIntoView({
        behavior: 'smooth',
        block: 'end',
        inline: 'nearest',
      });
    } else {
      event.target.closest('li').scrollIntoView(true);
    }
  };

  saveTest = async () => {
    const { questionsAnswers } = this.state;
    const {
      handleIsLoadingState,
      updateBehavioralState,
      isFromJobOffers,
      isMyProfile,
      closeModal,
      setClose,
      cardDataKey,
      fetchGamificationData,
    } = this.props;
    if (Object.keys(questionsAnswers).length < 30) {
      customSnackbar(
        `Você deve responder as 30 questões antes de finalizar o teste.`,
        'error'
      );
      return;
    }
    handleIsLoadingState(true);
    const response = await saveBehavioralTest(questionsAnswers);
    const { next_date } = await getBehavioralNextDate();

    if (response.message) {
      customSnackbar(
        `Erro ao gravar seus dados. Se o erro persistir, entre em contato
        com o suporte avisando sobre o erro: ${response.message}`,
        'error'
      );
      handleIsLoadingState(false);

      return;
    }
    updateBehavioralState && updateBehavioralState(response);
    const data = {
      is_done: true,
      is_enabled: false,
      next_date: next_date,
      results: response,
    };

    cardDataKey && this.updateCard(cardDataKey, data);
    await fetchGamificationData();
    if (isFromJobOffers || isMyProfile) setClose();
    Mixpanel.track('Salvou Teste Comportamental');
    customSnackbar(
      `Questionário preenchido com sucesso!
       Continue firme para ter um currículo
       cada vez mais completo.
      `,
      'confirmation'
    );
    closeModal();
    triggerPageView('student/testes/comportamental/cadastro');
    handleIsLoadingState(false);
  };

  renderList = (classes, isTwoColumns, list, wizard) => {
    const { current } = wizard;
    if (list && list.length) {
      let index = 0;
      let listItems = [];
      const listGroup = [];
      for (let counter = 1; counter <= list.length; ++counter) {
        listItems.push(list[counter - 1]);
        if (counter % 10 == false) {
          index = ++index;
          listGroup[index] = [
            <Card
              className={classes.card}
              key={index}
              ref={(ref) => (this.cardQuestions = ref)}
            >
              <CardBody>
                <GridList
                  className={classes.gridList}
                  cols={isTwoColumns ? 2 : 1}
                >
                  {listItems.map((item, index) => (
                    <GridListTile
                      id={item.id}
                      key={item.id}
                      classes={{ root: classes.gridListItem }}
                      cols={isTwoColumns ? 2 : 1}
                    >
                      <p className={classes.quizQuestionDescription}>
                        {item.option}
                      </p>
                      <div className={classes.gridListItemOptions}>
                        <RadioGroupWithButtons
                          groupName={`${item.id}`}
                          optionValue={`${
                            this.state.questionsAnswers[item.id]
                          }`}
                          onChange={this.handleChangeRadio}
                        />
                      </div>
                      {index + 1 < listItems.length && (
                        <Divider className={classes.divider} height="1" />
                      )}
                    </GridListTile>
                  ))}
                </GridList>
              </CardBody>
            </Card>,
          ];
          listItems = [];
        }
      }
      listGroup.shift();
      return listGroup.map((card) => (
        <Fragment key={card.id}>
          {card}
          <div className={classes.centerItems}>
            {this.state.step < listGroup.length ? (
              <NextStepTest onClick={() => current.nextStep()} />
            ) : (
              <FinallyButtonTest onClick={() => this.saveTest()} />
            )}
          </div>
          <FooterText>
            * Este questionário não se configura um teste psicológico. As
            decisões a respeito da escolha profissional devem,
            preferencialmente, contemplar avaliações de perfil conduzidas por um
            profissional habilitado.
          </FooterText>
        </Fragment>
      ));
    }
  };

  getBehavioralTestQuestionsHandle = async () => {
    this.props.handleIsLoadingState(true);

    const response = await getBehavioralTestQuestions();

    this.setState(
      {
        questionsList: response,
      },
      this.props.handleIsLoadingState(false)
    );
  };

  onStepChange = ({ activeStep }) => {
    this.setState({
      step: activeStep,
    });

    /** TODO: Do this better */
    const container = ReactDOM.findDOMNode(this.wizardHolder.current);
    setTimeout(() => {
      container.scrollIntoView({
        block: 'start',
        behavior: 'smooth',
      });
    }, 800);
  };

  render() {
    const { classes } = this.props;
    const isTwoColumns = window.innerWidth > 414;
    return (
      <Grid
        container
        className={`${classes.fullScreenGridContainer} ${classes.headerSpace}`}
        ref={this.wizardHolder}
      >
        <Grid item xs md={12} sm={12} className={classes.fullScreenGrid}>
          <p className={classes.quizDescription}>
            Este é um questionário que irá auxiliar você a identificar suas competências comportamentais para melhor entender seus talentos e escolher
            oportunidades do mercado de trabalho mais coerentes com seu perfil.
            Durante os próximos 5 minutos você responderá a 30 itens que
            descrevem comportamentos que podem ser mais ou menos presentes na
            sua forma de agir no dia a dia. Indique o quanto cada item está
            presente no seu comportamento rotineiro considerando uma escala em
            que 1 significa “ainda não sou capaz de realizar” e 5 significa “sou
            totalmente capaz de realizar”.
          </p>
          <Divider className={classes.dividerNav} height="1" />
          {!this.state.loading && this.state.questionsList ? (
            <StepWizard
              ref={this.wizard}
              nav={<Nav classes={classes} />}
              onStepChange={this.onStepChange}
              isLazyMount
            >
              {this.renderList(
                classes,
                isTwoColumns,
                this.state.questionsList,
                this.wizard
              )}
            </StepWizard>
          ) : null}
        </Grid>
      </Grid>
    );
  }
}

BehavioralTest.defaultProps = {
  setClose: undefined,
  isFromJobOffers: false,
  isMyProfile: false,
};

BehavioralTest.propTypes = {
  setClose: func,
  isFromJobOffers: bool,
  isMyProfile: bool,
  classes: shape({}).isRequired,
  updateBehavioralState: func.isRequired,
  closeModal: func.isRequired,
  handleIsLoadingState: func.isRequired,
};

export default withStyles(behavioralTestStyle)(BehavioralTest);
