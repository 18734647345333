import {
  Container,
  Subtitle,
  WhiteBox,
  FilterContainer,
  FilterReponsiveContainer,
  ScrollUp,
} from '../../Styles/BaseComponent';
import { useState, useEffect, useContext, useRef } from 'react';
import ResponsiveTitle from '../../UIComponents/TitleResponsive';
import AvailableTab from './InternalTabs/Available';
import FinishedTab from './InternalTabs/Finalized';
import FavoritesTab from './InternalTabs/Favorites';
import { LearningFilters } from '../../UIComponents/LearningFilter';
import { LearningContext } from 'views/Student/Learning/Context/LearningContext';
import ArrowUpIcon from 'assets/StudentLearning/arrowUp';
import { cancelGetAllLearnings } from 'views/Student/Learning/Services';
import BaseLayoutContext from 'contexts/base-layout';
import { SelfDevelopment } from '../SelfDevelopment';
import {
  ContainerNavigation,
  CustomGenericTabs,
} from '../FairAndEvents/styled';

export default function Learning({
  favoritesTabCount,
  finishedTabCount,
  availableTabCount,
  learningCountControl,
  queryFilter,
  filterState,
  filterOptions,
  count,
  academic_profile,
  activeTab,
  location,
  history,
  metadata,
}) {
  const [activeInternalTab, setActiveInternalTab] = useState(0);

  const {
    setQueryFilter,
    setAppliedFilters,
    setFilterState,
    setFilterOptions,
    appliedFilters,
  } = useContext(LearningContext);

  const selfDevelopment = metadata.has_self_development;

  const { universityColor } = useContext(BaseLayoutContext);

  const scrollUp = () => {
    if (startPage.current) {
      startPage.current.scrollIntoView({ block: 'start', inline: 'nearest' });
    }
  };

  useEffect(() => {
    if (!selfDevelopment) {
      setActiveInternalTab(1);
    }
  }, []);

  useEffect(() => {
    if (location?.state?.selfDevelopment) {
      setActiveInternalTab(1);
    }
  }, [location])

  const Tabs = [
    {
      activeTab: 0,
      component: (
        <AvailableTab
          activeInternalTab={activeInternalTab}
          setFilterOptions={setFilterOptions}
          queryFilter={queryFilter}
          availableTabCount={availableTabCount}
          learningCountControl={learningCountControl}
          activeTab={activeTab}
          scrollUp={scrollUp}
        />
      ),
    },
    {
      activeTab: 1,
      component: (
        <SelfDevelopment
          academic_profile={academic_profile}
          history={history}
          location={location}
          activeTab={activeTab}
          count={count}
        />
      ),
    },
    {
      activeTab: 2,
      component: (
        <FavoritesTab
          activeInternalTab={activeInternalTab}
          setFilterOptions={setFilterOptions}
          queryFilter={queryFilter}
          favoritesTabCount={favoritesTabCount}
          learningCountControl={learningCountControl}
          activeTab={activeTab}
          scrollUp={scrollUp}
        />
      ),
    },
    {
      activeTab: 3,
      component: (
        <FinishedTab
          activeInternalTab={activeInternalTab}
          setFilterOptions={setFilterOptions}
          queryFilter={queryFilter}
          finishedTabCount={finishedTabCount}
          learningCountControl={learningCountControl}
          activeTab={activeTab}
          scrollUp={scrollUp}
        />
      ),
    },
  ];

  useEffect(() => {
    learningCountControl();
  }, [activeInternalTab]);

  const handleTabChange = (_, value) => {
    setActiveInternalTab(value);
    setFilterState({});
    setFilterOptions({});
    setAppliedFilters({});
    setQueryFilter(null);
    cancelGetAllLearnings();
  };

  const renderPrincipalTabs = (step) => {
    const returnTabs = Tabs.find((tab) => tab.activeTab === step);
    return returnTabs.component;
  };

  const startPage = useRef();

  const filterModal = (
    <LearningFilters
      activeTab={activeTab}
      activeInternalTab={activeInternalTab}
      filterState={filterState}
      filterOptions={filterOptions}
      appliedFilters={appliedFilters}
      queryFilter={queryFilter}
      setFilterState={setFilterState}
      setFilterOptions={setFilterOptions}
      setAppliedFilters={setAppliedFilters}
      setQueryFilter={setQueryFilter}
    />
  );

  return (
    <Container>
      <i ref={startPage} />
      <FilterReponsiveContainer>
        <LearningFilters
          activeTab={activeTab}
          activeInternalTab={activeInternalTab}
          filterState={filterState}
          filterOptions={filterOptions}
          queryFilter={queryFilter}
          setFilterState={setFilterState}
          setFilterOptions={setFilterOptions}
          setQueryFilter={setQueryFilter}
        />
      </FilterReponsiveContainer>
      <ResponsiveTitle
        lgText="Conteúdos de aprendizagem recomendados para você"
        smText="Conteúdos de aprendizagem recomendados para você"
        xsText="Oportunidades em destaque para você"
        universityColor={universityColor}
      />

      <Subtitle>
        <strong>{academic_profile.name}</strong>, encontramos{' '}
        <strong>{count}</strong> conteúdos que irão te ajudar a desenvolver suas
        competências. :)
      </Subtitle>
      <WhiteBox>
        <ContainerNavigation>
          <CustomGenericTabs
            activeTab={activeInternalTab}
            handleTabChange={handleTabChange}
            tabs={[
              `Técnicos (${availableTabCount})`,
              `Comportamentais`,
              `Favoritados (${favoritesTabCount})`,
              `Finalizados (${finishedTabCount})`,
            ]}
            hideFirstTab={!selfDevelopment}
            containerButtonsPosition={'unset'}
            zIndex={10}
            buttons={[filterModal]}
          />
        </ContainerNavigation>
      </WhiteBox>
      {renderPrincipalTabs(activeInternalTab)}
      <ScrollUp onClick={() => scrollUp()}>
        <ArrowUpIcon color="#7e7e85" size={16} />
      </ScrollUp>
    </Container>
  );
}
