import React, { Fragment, useEffect, useState, useContext } from 'react';
import { func, shape } from 'prop-types';

import { Button } from '@material-ui/core';
import { CardMembership } from '@material-ui/icons';
import withStyles from '@material-ui/core/styles/withStyles';
import classnames from 'classnames';
import moment from 'moment';
import 'moment/locale/pt-br';
import CloseIcon from '@material-ui/icons/Close';
import { TbCertificate } from 'react-icons/tb';

import { getQuizzCertificateData } from 'services/student/quizzes';
import GridContainer from 'components/Grid/GridContainer';
import CompanyHeader from 'components/ViewsHeader/CompanyViewsHeader';
import CertificateContainer from 'components/Certificate/CertificateContainer';
import dashboardStyle from 'assets/jss/material-dashboard-pro-react/views/dashboardStyle';
import backgroundImage from 'assets/img/contents.jpg';
import { Link } from 'react-router-dom';
import livesDataS01 from './components/LivesDataS01';
import livesDataS02 from './components/LivesDataS02';
import livesDataS03 from './components/LivesDataS03';
import BaseLayoutContext from 'contexts/base-layout';
import useDefineColor from 'constants/colors';

import ContentItems from './components/ContentItems';
import { StyledButton } from './styles';

const Contents = (props) => {
  const [activeTabContent, setActiveTabContent] = useState(0);
  const [certificateData, setCertificateData] = useState(null);
  const { universityColor } = useContext(BaseLayoutContext);

  const [tabs] = useState([
    `Dicas do mercado de trabalho`,
    `Mentorias`,
    `O novo mundo do trabalho`,
  ]);
  const {
    handleIsLoadingState,
    location,
    classes,
    openModalWithContent,
    metadata,
  } = props;

  const getCertificateData = async () => {
    const livesIds = [];

    switch (activeTabContent) {
      case 0:
        Promise.all(
          livesDataS01.map((live) => live.form && livesIds.push(live.id))
        );
        break;
      // case 1:
      //   Promise.all(
      //     livesDataS02.map((live) => live.form && livesIds.push(live.id))
      //   );

      default:
        return null;
    }
    if (livesIds.length > 0) {
      const certificate = await getQuizzCertificateData({ lives: livesIds });

      if (!certificate.message) {
        setCertificateData(certificate);
      }
    }
    return null;
  };

  useEffect(() => {
    if (location.search) {
      const urlParams = new URLSearchParams(location.search);
      setActiveTabContent(parseInt(urlParams.get('tab')));
    }
    if (metadata.role === 'student') getCertificateData();
    handleIsLoadingState(false);
  }, []);

  useEffect(() => {
    setCertificateData(null);
    if (metadata.role === 'student') getCertificateData();
  }, [activeTabContent]);

  const showCertificate = async () => {
    const {
      student,
      total_hours: totalHours,
      completion_date,
    } = certificateData;

    const completionDate = moment(completion_date).locale('pt-br').format('LL');

    openModalWithContent(
      <CertificateContainer
        trailTitle={`Programa Sucesso do Estudante - ${tabs[activeTabContent]}`}
        handleIsLoadingState={handleIsLoadingState}
        certificateData={{ student, totalHours, completionDate }}
      />,
      `Certificado ${`Programa Sucesso do Estudante - ${tabs[activeTabContent]}`}`,
      null,
      getCertificateData
    );
  };

  return (
    <Fragment>
      <CompanyHeader
        title="Oportunidades de Aprendizagem"
        activeTab={activeTabContent}
        backgroundImage={backgroundImage}
      />

      <GridContainer direction="column">
        <StyledButton isContent className={classes.marginLeftAuto}>
          <Link
            to={{
              pathname: '/cursos-e-eventos',
              search: location.search,
              state: { selfDevelopment: 1 },
            }}
          >
            <CloseIcon style={{ fontSize: '40px' }} />
          </Link>
        </StyledButton>
        {activeTabContent === 0 && metadata.role === 'student' && (
          <Fragment>
            <Button
              variant="cotained"
              style={{
                color: universityColor,
              }}
              className={classnames({
                [classes.certButton]: true,
                [classes.disabled]: !certificateData,
              })}
              disabled={!certificateData}
              onClick={showCertificate}
            >
              <TbCertificate size={36} color={universityColor || '#009291'} />
              Emitir Certificado
            </Button>
            <p className={classes.certificateMessage}>
              Para habilitar o botão Emitir Certificado, é necessário responder
              todas as perguntas dos vídeos abaixo!{' '}
            </p>
          </Fragment>
        )}
        <GridContainer direction="row">
          {activeTabContent === 0 && (
            <ContentItems
              {...props}
              data={livesDataS01}
              getCertificateData={getCertificateData}
            />
          )}
          {activeTabContent === 1 && (
            <ContentItems {...props} data={livesDataS02} />
          )}
          {activeTabContent === 2 && (
            <ContentItems {...props} data={livesDataS03} />
          )}
        </GridContainer>
      </GridContainer>
    </Fragment>
  );
};

Contents.propTypes = {
  handleIsLoadingState: func.isRequired,
  classes: shape.isRequired,
  location: shape.isRequired,
  openModalWithContent: func.isRequired,
  metadata: shape({}).isRequired,
};

export default withStyles(dashboardStyle)(Contents);
