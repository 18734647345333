function searchPage(page, limit, queryFilter, debouncedSearchTerm) {
  let newPage = `limit=${limit}&offset=${(page - 1) * limit || 0}`;
  const searchParam = new URLSearchParams(queryFilter);

  if (queryFilter && searchParam.get('name') == null) {
    newPage = `name=${debouncedSearchTerm}&${queryFilter}&${newPage}`;
    return newPage;
  }
  if (queryFilter && searchParam.get('name') != null) {
    newPage = `${queryFilter}&${newPage}`;
    return newPage;
  }
  if (debouncedSearchTerm) {
    newPage = `name=${debouncedSearchTerm}&${newPage}`;
    return newPage;
  }
  if (queryFilter) {
    newPage = `${queryFilter}&${newPage}`;
    return newPage;
  }
  return newPage;
}

function searchQuery(limit, queryFilter, debouncedSearchTerm) {
  let searchQuery = `limit=${limit}&offset=0`;
  if (queryFilter && debouncedSearchTerm) {
    searchQuery = `name=${debouncedSearchTerm}&${queryFilter}&${searchQuery}`;
    return searchQuery;
  }
  if (debouncedSearchTerm) {
    searchQuery = `name=${debouncedSearchTerm}&${searchQuery}`;
  }
  if (queryFilter) {
    searchQuery = `${queryFilter}&${searchQuery}`;
  }
  return searchQuery;
}

export { searchQuery, searchPage };
