import React, { Fragment } from 'react';
import { object, func, bool } from 'prop-types';
import { Grid, Button } from '@material-ui/core';
import KeyboardBackspace from '@material-ui/icons/KeyboardBackspace';

import BehavioralTest from './BehavioralTest';
import mergeContextsHelper from 'utils/mergeContextsHelper';
import withStyles from '@material-ui/core/styles/withStyles';

import behavioralTestStyle from 'assets/jss/material-dashboard-pro-react/views/behavioralTestStyle';
import classNames from 'classnames';
import useDefineColor from 'constants/colors';

const BehavioralTestHelpContent = (props) => {
  const {
    classes,
    behavioralTestIsDone: isTestDone,
    behavioralTestIsEnabled: isTestEnabled,
    openModalWithContent,
    universityColor,
  } = props;

  return (
    <Fragment>
      <Grid container className={classes.fullScreenGridContainer}>
        <Grid item xs md={12} sm={12} className={classes.fullScreenGrid}>
          <h2
            className={
              classes[`${useDefineColor(universityColor)}cardTitleHelpContent`]
            }
          >
            Competências comportamentais
          </h2>
          <p className={classes.cardCategory}>
          Suas competências comportamentais refletem suas capacidades, competências e talentos. É por meio delas que você consegue gerar resultados e impacto. Com as suas competências comportamentais, as organizações as organizações conseguem
            identificar o seu potencial de entrega profissional.
          </p>
          <br />
          <iframe
            className={classes.videos}
            src="https://www.youtube.com/embed/588akOr4VYE"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          />
        </Grid>
      </Grid>
    </Fragment>
  );
};

BehavioralTestHelpContent.propTypes = {
  classes: object,
  openModalWithContent: func,
  updateBehavioralStateFn: func,
  openSnackbarFn: func,
  closeModalFn: func,
  handleIsLoadingStateFn: func,
  behavioralTestIsDone: bool,
};

export default withStyles(behavioralTestStyle)(BehavioralTestHelpContent);
