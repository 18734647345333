import * as Yup from 'yup';

const today = new Date();
today.setHours(0, 0, 0, 0);

export const schemaValidation = Yup.object()
  .shape({
    name: Yup.string().required('Campo obrigatório'),
    start_offer_at: Yup.date()
      .typeError('Informe a data de início')
      .required('Campo obrigatório')
      .min(today, 'A data deve ser no mínimo hoje'),
    end_offer_at: Yup.date()
      .required('Campo obrigatório')
      .typeError('Informe a data de término')
      .min(
        Yup.ref('start_offer_at'),
        'A data final precisa ser maior do que a data inicial'
      ),
    publication_type: Yup.string().required('Campo obrigatório '),
    application_type: Yup.string().ensure().required('Campo obrigatório'),
    modality_type: Yup.string().ensure().required('Campo obrigatório'),
  })
  .when((values, schema) => {
    if (values.administrative_selection_process) {
      const validateAffirmativeProcess =
        values.disability.length !== 0 ||
        values.color_or_race.length !== 0 ||
        values.gender_identity.length !== 0;
      return schema.shape({
        gender_identity: Yup.string().test(
          'has_at_least_one_affirmative_process',
          'Para seguir em frente, selecione pelo menos um tipo de inclusão',
          (_) => {
            return validateAffirmativeProcess;
          }
        ),
      });
    }
    return schema;
  })
  .when((values, schema) => {
    if (values.modality_type !== 'remote') {
      return schema.shape({
        zip_code: Yup.string().ensure().required('Campo obrigatório'),
        
      });
    }
    return schema;
  })
  .when((values, schema) => {
    if (values.publication_type === 'email') {
      return schema.shape({
        job_email: Yup.string()
          .required('Campo obrigatório ')
          .email('Insira um e-mail válido'),
        divulgation_link: Yup.string().url('Insira um link válido'),
      });
    }
    if (values.publication_type === 'link') {
      return schema.shape({
        job_email: Yup.string(),
        divulgation_link: Yup.string()
          .url('Insira um link válido')
          .required('Campo obrigatório'),
      });
    }
    return schema;
  });
