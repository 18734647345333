import React, { useContext } from 'react';
import PropTypes, { shape } from 'prop-types';

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
import { Paper, Hidden } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import CloseIcon from '@material-ui/icons/Close';
import { Link } from 'react-router-dom';

import IcoBrain from 'assets/img/icons/icoBrain.png';
import IcoLamp from 'assets/img/icons/icoLamp.png';
import IcoUser from 'assets/img/icons/icoUser.png';
import IcoSuccess from 'assets/img/icons/icoCursosAdicionais.png';

import ViewsHeader from 'components/ViewsHeader/ViewsHeader';

import dashboardStyle from 'assets/jss/material-dashboard-pro-react/views/dashboardStyle';
import {
  getLearningTrails,
  saveTrailProgress,
  getTrailCertificateData,
} from 'services/student/learningTrails';
import { StyledButton } from '../Contents/styles';
import CardComposition from '../../components/CardComposition/CardComposition';

import TrailProgressCard from './TrailProgressCard';
import TrailContent from './TrailContent';
import LearningTrailsData from './LearningTrailsData';
import successOvercomingData from './data/successOvercomingData';
import TrailWithForm from './components/TrailWithForm';
import BaseLayoutContext from 'contexts/base-layout';

const SelfKnowledgeContent = (props) => {
  const { trailOnSubmit, trailOnUpdateStep } = props;
  const { universityColor } = useContext(BaseLayoutContext);

  return (
    <TrailContent
      data={LearningTrailsData.selfknowledge}
      onSubmit={trailOnSubmit}
      onUpdateStep={trailOnUpdateStep}
      universityColor={universityColor}
      {...props}
    />
  );
};

const SoftSkillsContent = (props) => {
  const { trailOnSubmit, trailOnUpdateStep } = props;
  const { universityColor } = useContext(BaseLayoutContext);

  return (
    <TrailContent
      data={LearningTrailsData.softSkills}
      onSubmit={trailOnSubmit}
      onUpdateStep={trailOnUpdateStep}
      universityColor={universityColor}
      {...props}
    />
  );
};

const LeadershipContent = (props) => {
  const { universityColor } = useContext(BaseLayoutContext);

  const { trailOnSubmit, trailOnUpdateStep } = props;
  return (
    <TrailContent
      data={LearningTrailsData.leadership}
      onSubmit={trailOnSubmit}
      onUpdateStep={trailOnUpdateStep}
      universityColor={universityColor}
      {...props}
    />
  );
};

const SuccessOvercoming = (props) => {
  const { universityColor } = useContext(BaseLayoutContext);

  const { trailOnSubmit, trailOnUpdateStep } = props;
  return (
    <TrailWithForm
      data={successOvercomingData}
      onSubmit={trailOnSubmit}
      onUpdateStep={trailOnUpdateStep}
      universityColor={universityColor}
      {...props}
    />
  );
};

class LearningTrails extends React.Component {
  state = {
    selfKnowledgeProgress: 0,
    softSkillsProgress: 0,
    leadershipProgress: 0,
    successOvercomingProgress: 0,
    selfKnowledgeEnabled: false,
    leadershipEnabled: false,
    softSkillsEnabled: false,
    successOvercomingEnabled: false,
  };

  async UNSAFE_componentWillMount() {
    const { metadata } = this.props;
    if (metadata.role === 'student') {
      const learningTrails = await getLearningTrails();
      this.updateLearningTrailsState(learningTrails);
    } else {
      this.updateLearningTrailsState();
    }
  }

  componentDidMount() {
    const { handleIsLoadingState } = this.props;
    handleIsLoadingState(false);
  }

  trailOnSubmit = () => {
    const { closeModal } = this.props;

    closeModal();
  };

  trailOnUpdateStep = async (data) => {
    // data.progress comes as 0.33333, that's the reason we round it
    const { metadata } = this.props;
    if (metadata.role === 'student') {
      const progress = Math.round(data.progress * 100);

      const response = await saveTrailProgress({ id: data.trailId, progress });

      if (!response.message) {
        this.updateLearningTrailsState(response);
      }
    }
  };

  updateLearningTrailsState = (data) => {
    const { metadata } = this.props;
    if (metadata.role === 'student') {
      this.setState({
        selfKnowledgeProgress: data.selfknowledge.progress,
        softSkillsProgress: data.softskills.progress,
        leadershipProgress: data.leadership.progress,
        successOvercomingProgress: data.successOvercoming.progress,

        selfKnowledgeEnabled: data.selfknowledge.enabled,
        softSkillsEnabled: data.softskills.enabled,
        leadershipEnabled: data.leadership.enabled,
        successOvercomingEnabled: data.successOvercoming.enabled,
      });
    } else {
      this.setState({
        selfKnowledgeProgress: 100,
        softSkillsProgress: 100,
        leadershipProgress: 100,
        successOvercomingProgress: 100,

        selfKnowledgeEnabled: true,
        softSkillsEnabled: true,
        leadershipEnabled: true,
        successOvercomingEnabled: true,
      });
    }
  };

  render() {
    const { classes, openModalWithContent, metadata, location } = this.props;
    const {
      welcomeMessageVisible,
      selfKnowledgeProgress,
      selfKnowledgeEnabled,
      softSkillsProgress,
      softSkillsEnabled,
      leadershipProgress,
      leadershipEnabled,
      successOvercomingProgress,
      successOvercomingEnabled,
    } = this.state;

    const propsCopy = { ...this.props };
    delete propsCopy.classes;

    return (
      <div className={classes.container}>
        <ViewsHeader title="Oportunidades de Aprendizagem" {...propsCopy} />
        <GridContainer style={{ marginTop: '30px' }}>
          <Grid item xs md={12}>
            <StyledButton className={classes.marginLeftAuto}>
              <Link
                to={{
                  pathname: '/cursos-e-eventos',
                  search: location.search,
                  state: { selfDevelopment: 1 },
                }}
              >
                <CloseIcon style={{ fontSize: '40px' }} />
              </Link>
            </StyledButton>
            <Paper
              className={classes.welcomeMessage}
              elevation={0}
              hidden={welcomeMessageVisible}
            >
              <h4 className={classes.description}>
                Desenvolvemos um programa de autogestão de vida e carreira para
                você dar um passo de como construir seu projeto de vida.
                Percorra as trilhas de Autoconhecimento, Soft Skills,
                Protagonismo e Sucesso e Superação. Cada trilha de aprendizagem
                vai te ajudar a descobrir melhor seus talentos, paixões e
                interesses e como tudo isso pode te ajudar a fazer uma real
                diferença no mundo.. E se quiser se aprofundar, preparamos uma
                curadoria de vídeos, livros e filmes para você em cada trilha!
              </h4>
            </Paper>
          </Grid>

          <div className={classes.gridStyle}>
            <Grid container direction="row" spacing={16}>
              <GridItem item xs={12} sm={6}>
                <CardComposition
                  classes={classes}
                  colorScheme="green"
                  iconPath={IcoLamp}
                  iconDescription="Autoconhecimento"
                  cardTitleText="Autoconhecimento"
                  CardContent={TrailProgressCard}
                  openModalWithContentFn={openModalWithContent}
                  trailTitle="Autoconhecimento"
                  TrailContent={SelfKnowledgeContent}
                  trailProgress={selfKnowledgeProgress}
                  trailOnSubmit={this.trailOnSubmit}
                  trailOnUpdateStep={this.trailOnUpdateStep}
                  trailEnabled={selfKnowledgeEnabled}
                />
              </GridItem>

              <GridItem item xs={12} sm={6}>
                <CardComposition
                  classes={classes}
                  colorScheme="purple"
                  iconPath={IcoUser}
                  iconDescription="Soft Skills"
                  cardTitleText="Soft Skills"
                  CardContent={TrailProgressCard}
                  openModalWithContentFn={openModalWithContent}
                  trailTitle="Soft Skills"
                  TrailContent={SoftSkillsContent}
                  trailProgress={softSkillsProgress}
                  trailOnSubmit={this.trailOnSubmit}
                  trailOnUpdateStep={this.trailOnUpdateStep}
                  trailEnabled={softSkillsEnabled}
                />
              </GridItem>

              <GridItem item xs={12} sm={6}>
                <CardComposition
                  classes={classes}
                  colorScheme="blue"
                  iconPath={IcoBrain}
                  iconDescription="Protagonismo"
                  cardTitleText="Protagonismo"
                  CardContent={TrailProgressCard}
                  openModalWithContentFn={openModalWithContent}
                  trailTitle="Protagonismo"
                  TrailContent={LeadershipContent}
                  trailProgress={leadershipProgress}
                  trailOnSubmit={this.trailOnSubmit}
                  trailOnUpdateStep={this.trailOnUpdateStep}
                  trailEnabled={leadershipEnabled}
                />
              </GridItem>

              <GridItem item xs={12} sm={6}>
                <CardComposition
                  classes={classes}
                  colorScheme="yellow"
                  iconPath={IcoSuccess}
                  iconDescription="Sucesso e Superação"
                  cardTitleText="Sucesso e Superação"
                  CardContent={TrailProgressCard}
                  openModalWithContentFn={openModalWithContent}
                  trailTitle="Sucesso e Superação"
                  TrailContent={SuccessOvercoming}
                  trailProgress={successOvercomingProgress}
                  trailOnSubmit={this.trailOnSubmit}
                  trailOnUpdateStep={this.trailOnUpdateStep}
                  trailEnabled={successOvercomingEnabled}
                  trail={successOvercomingData.id}
                  getTrailCertificateData={getTrailCertificateData}
                />
              </GridItem>
            </Grid>
          </div>
        </GridContainer>
      </div>
    );
  }
}

LearningTrails.propTypes = {
  metadata: shape({}).isRequired,
  classes: PropTypes.shape.isRequired,
  openModalWithContent: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  handleIsLoadingState: PropTypes.func.isRequired,
};

SuccessOvercoming.propTypes = LearningTrails.propTypes;
SelfKnowledgeContent.propTypes = LearningTrails.propTypes;
SoftSkillsContent.propTypes = LearningTrails.propTypes;
LeadershipContent.propTypes = LearningTrails.propTypes;

export default withStyles(dashboardStyle)(LearningTrails);
