import React, {
  useContext,
  useRef,
  useState,
  useEffect,
  useCallback,
} from 'react';
import logoWorkaloveLaranja from 'assets/img/icons/workaloveLogoLaranja.svg';
import LogosAnimated from '../UIComponents/LogosAnimated';
import { Form } from '@unform/web';
import { errors } from 'constants/errors';

import LoginForgotPassword from './ForgotPassword';

import {
  Container,
  ContainerLogin,
  ContainerAllLogos,
  Titles,
  Paragraph,
  ButtonLogin,
  ContainerButtonFooter,
  ButtonRegister,
  ContainerWorkalove,
  FragmentContainer,
  ContainerInCenter,
  BoxLogos,
  ButtonPassword,
  Footer,
  ContainerSubTitles,
  ContainerImage,
  ButtonOutlined,
  CreateAccountDiv,
  CreateAccount,
  CaptchaContainer,
  CaptchaSkeleton,
} from '../styles';
import AuthContext from 'contexts/authentication';
import BaseLayoutContext from 'contexts/base-layout';
import { login } from 'services/login/authenticate';
import CreateAccountCompany from './BaseLogin/CreateAccountCompany';
import InputUsernameLogin from '../UIComponents/InputUsername';
import InputPasswordLogin from '../UIComponents/InputPassword';
import EmailPassword from './BaseLogin/EmailPassword';
import subdomainService from 'services/subdomain.service';
import { defaultsSubdomain } from 'constants/subdomains';
import {
  getInfoStudent,
  getUniversityLogos,
} from 'services/login/authenticate';
import ImageSkeleton from '../styles/ImageSkeleton';
import data from 'views/Student/Learning/Utils/workaData/data';
import {
  verifyRecoverToken,
  confirmNewPassword,
} from 'services/login/recoverPassword';
import useMediaQuery from 'hooks/useMediaQuery';
import { verifyRecaptchaV3 } from 'services/companies';
import { captcha } from 'constants/configs';
import { Helmet } from 'react-helmet';
import { customSnackbar } from 'components/CustomSnackBar/customSnackbar';

export default function LoginCompany({
  match,
  university,
  isRecovery = false,
  history,
}) {
  const { routerOnUserLogin, isInvalidAuthenticationToken } = useContext(
    AuthContext
  );
  const { openSnackbar, handleIsLoadingState } = useContext(BaseLayoutContext);
  const [showForgotPassword, setShowForgotPassword] = useState(false);
  const [showCreateAccount, setShowCreateAccount] = useState(false);
  const [openModalInformation, setOpenModalInformatio] = useState(false);
  const [contentLogin, setContentLogin] = useState(true);
  const [modalEmailSent, setModalEmailSent] = useState(false);
  const dnaColor = '#009291';
  const formLoginCompany = useRef();
  const formRecoveryCompany = useRef();
  const subdomain = subdomainService.getSubdomain();
  const urlCompany = '/login/empresa';
  const [dataInfos, setDataInfos] = useState(null);
  const [universityColor, setUniversityColor] = useState(dnaColor);
  const [logo, setLogo] = useState(logoWorkaloveLaranja);
  const [backgroundImage, setBackgroundImage] = useState(null);
  const [skeleton, setSkeleton] = useState(false);
  const [grecaptcha, setGrecaptcha] = useState(null);
  const [loadedCaptcha, setLoadedCaptcha] = useState(false);
  const [confirmCaptcha, setConfirmCaptcha] = useState(false);

  const [token, setToken] = useState('');
  const [tokenExpired, setTokenExpired] = useState(false);
  const [recoveryErrors, setRecoveryErros] = useState({
    password: '',
    confirmPassword: '',
  });

  const universityName = match.params.universidade ?? university;
  const isMobile = useMediaQuery('(max-width: 850px)');
  const controlText = useMediaQuery('(max-height: 730px)');

  function handleForgotPasswordClick() {
    setShowForgotPassword(true);
    setContentLogin(false);
  }

  function handleCreateAccount() {
    setShowCreateAccount(true);
    setContentLogin(false);
  }

  const handleLogin = async (data) => {
    if (!confirmCaptcha) {
      customSnackbar(
        'Para continuar, complete a verificação CAPTCHA.',
        'error'
      );
      return;
    }
    handleIsLoadingState(true);
    const loginData = { ...data, company: true };
    let loginCompany = await login(
      subdomain.toLocaleLowerCase() === defaultsSubdomain.company
        ? urlCompany
        : match.path,
      loginData
    );

    if (loginCompany.isLogged) {
      if (match.params?.universidade && match.params?.id) {
        loginCompany.data['params'] = match.params;
      }
      routerOnUserLogin(
        loginCompany.data,
        undefined,
        window.location.href.split('?')[1]
      );
      routerOnUserLogin(
        loginCompany.data,
        undefined,
        window.location.href.split('?')[1]
      );
    } else {
      if (loginCompany.data.detail) {
        handleIsLoadingState(false);
        return;
      }
      const message = errors.hasOwnProperty(loginCompany.data.code)
        ? errors[loginCompany.data.code]
        : 'Não foi possível realizar o seu login. Tente novamente';
      handleIsLoadingState(false);
      openSnackbar(message, true);
    }
  };

  const confirmRecovery = async (data) => {
    setRecoveryErros({});
    const validatePasswordRegex = /^(?=.*[A-Za-z])(?=.*\d).{8,}$/;
    if (!validatePasswordRegex.test(data.password)) {
      setRecoveryErros({
        password:
          'A senha deve conter no mínimo 8 caracteres, uma letra e um número.',
      });
      return;
    }
    if (data.password !== data.confirmPassword) {
      setRecoveryErros({ confirmPassword: 'As senhas devem ser iguais.' });
      return;
    }
    try {
      const response = await confirmNewPassword({ ...data, token });
      openSnackbar('Senha alterada com sucesso', false);
      if (response.status.toLocaleLowerCase() === 'ok') {
        history.push({
          pathname: '/login/empresa',
          state: {
            recoverSuccess: true,
          },
        });
      }
    } catch (e) {
      openSnackbar('Falha ao alterar senha, tente novamente.', true);
    }
  };

  useEffect(() => {
    if (isRecovery) {
      const token = new URLSearchParams(window.location.hash.split('?')[1]).get(
        'token'
      );
      if (!token || token == null) {
        history.push(`/login/empresa`);
      }
      setToken(token);
      (async () => {
        try {
          await verifyRecoverToken({ token });
        } catch (e) {
          setTokenExpired(true);
          handleForgotPasswordClick();
        }
      })();
    }
    if (history.location?.state?.recoverSuccess) {
      openSnackbar('Senha alterada com sucesso');
    }
  }, []);

  const returnImageLogin = (image) => {
    if (skeleton === true) {
      return <ImageSkeleton />;
    }

    if (image) {
      return <ContainerImage image={image} />;
    }

    if (!image) {
      return (
        <ContainerAllLogos>
          {!isMobile && <LogosAnimated color={universityColor} />}

          <div>
            {!controlText && (
              <Titles isLogo lineHeightMobile="31px">
                <strong>
                  Encontre os melhores estudantes para suas vagas.
                </strong>
              </Titles>
            )}
            <Paragraph>
              Acesse o banco de dados das instituições de <br /> ensino mais
              inovadoras do país e contrate <br /> mais rápido os melhores
              estudantes.
            </Paragraph>
          </div>
        </ContainerAllLogos>
      );
    }
  };

  useEffect(
    () => () => {
      window.removeEventListener('loadGrecaptcha');
    },
    []
  );

  useEffect(() => renderCaptcha(), [grecaptcha, showCreateAccount]);

  useEffect(() => {
    const handleLoadGrecaptcha = (event) => {
      setGrecaptcha(event.detail);
    };

    if (grecaptcha == null) {
      window.addEventListener('loadGrecaptcha', handleLoadGrecaptcha);
    }

    return () =>
      window.removeEventListener('loadGrecaptcha', handleLoadGrecaptcha);
  }, [grecaptcha]);

  const renderCaptcha = useCallback(() => {
    if (grecaptcha == null) return;
    
    if (!showCreateAccount) {
      window.removeEventListener('loadGrecaptcha');
      try {
        grecaptcha.render('captchaDivCompanyLogin', {
          sitekey: captcha.siteKey,
          callback: (token) => verifyCaptcha(token),
        });
        setTimeout(() => setLoadedCaptcha(true), 500);
      } catch (e) {
        return;
      }
    }
  }, [grecaptcha]);

  const verifyCaptcha = (token) => {
    verifyRecaptchaV3(token);
    setConfirmCaptcha(true);
  };

  return (
    <Container color={universityColor}>
      <Helmet>
        <script
          src={`https://www.google.com/recaptcha/api.js?onload=loadCallback&render=explicit`}
          async
        ></script>
        <script async>
          {`
          function loadCallback() {
            window.dispatchEvent(new CustomEvent('loadGrecaptcha', {detail:grecaptcha}))
            }`}
        </script>
      </Helmet>
      <BoxLogos>{returnImageLogin(backgroundImage)}</BoxLogos>

      {contentLogin === false && showCreateAccount && (
        <CreateAccountCompany
          match={match}
          setContentLogin={setContentLogin}
          showCreateAccount={showCreateAccount}
          setShowCreateAccount={setShowCreateAccount}
          openModalInformation={openModalInformation}
          logo={logo}
          universityColor={universityColor}
        />
      )}

      {contentLogin === false && showForgotPassword && (
        <LoginForgotPassword
          isCompanyConfig
          modalEmailSent={modalEmailSent}
          setModalEmailSent={setModalEmailSent}
          contentLogin={contentLogin}
          setContentLogin={setContentLogin}
          showForgotPassword={showForgotPassword}
          setShowForgotPassword={setShowForgotPassword}
          universityColor={universityColor}
          expiredToken={tokenExpired}
        />
      )}

      {modalEmailSent && (
        <EmailPassword
          modalEmailSent={modalEmailSent}
          setModalEmailSent={setModalEmailSent}
          setContentLogin={setContentLogin}
          showForgotPassword={showForgotPassword}
        />
      )}

      {contentLogin && (
        <ContainerLogin contentImage={dataInfos?.image?.file}>
          <FragmentContainer>
            <ContainerInCenter logoWidth="220px" isCompany>
              <img src={logoWorkaloveLaranja} alt="" />
            </ContainerInCenter>
            {!isRecovery && (
              <Titles presentation>
                <p>Seja bem-vindo! Acesse sua conta.</p>
              </Titles>
            )}
            {isRecovery && (
              <>
                <Titles isRecovery>
                  <p>Nova senha</p>
                </Titles>
                <ContainerSubTitles>
                  <p>Preencha os campos abaixo com a sua nova senha.</p>
                </ContainerSubTitles>
              </>
            )}
            {!isRecovery && (
              <div className="form">
                <Form ref={formLoginCompany} onSubmit={handleLogin}>
                  <InputUsernameLogin
                    name="username"
                    label="E-mail de acesso"
                    universityColor={universityColor}
                    placeholder="Digite aqui..."
                  />

                  <InputPasswordLogin
                    name="password"
                    label="Senha"
                    type="password"
                    universityColor={universityColor}
                    placeholder="Digite aqui..."
                  />
                  <span></span>
                  <ButtonPassword
                    color={universityColor}
                    onClick={handleForgotPasswordClick}
                    type="button"
                  >
                    Esqueceu sua senha ?
                  </ButtonPassword>
                  <div className="button-login-container">
                    <ButtonLogin color={universityColor} type="submit">
                      Entrar
                    </ButtonLogin>
                  </div>
                </Form>
                <CaptchaContainer marginTop="-15px" marginBottom="-35px">
                  <div
                    id="captchaDivCompanyLogin"
                    style={{ display: loadedCaptcha ? 'block' : 'none' }}
                  ></div>
                  <CaptchaSkeleton show={!loadedCaptcha} />
                </CaptchaContainer>
              </div>
            )}
            {isRecovery && (
              <div className="form">
                <Form ref={formRecoveryCompany} onSubmit={confirmRecovery}>
                  <InputPasswordLogin
                    name="password"
                    label="Senha"
                    type="password"
                    universityColor={dnaColor}
                    placeholder="Digite aqui..."
                    errorMessage={recoveryErrors.password}
                    buttonShowPassword
                  />

                  <InputPasswordLogin
                    name="confirmPassword"
                    label="Confirmar Senha"
                    type="password"
                    universityColor={dnaColor}
                    placeholder="Digite aqui..."
                    errorMessage={recoveryErrors.confirmPassword}
                    buttonShowPassword
                  />
                  <span></span>
                  <ButtonLogin color={dnaColor} type="submit">
                    Confirmar
                  </ButtonLogin>
                </Form>
              </div>
            )}
            <CreateAccountDiv>
              <div className="button-login-container">
                <ButtonOutlined
                  color={universityColor}
                  onClick={handleCreateAccount}
                >
                  Criar conta
                </ButtonOutlined>
              </div>
            </CreateAccountDiv>
          </FragmentContainer>

          <ContainerWorkalove>
            <Footer>
              By <strong>Workalove</strong>
            </Footer>
          </ContainerWorkalove>
        </ContainerLogin>
      )}
    </Container>
  );
}
