import axios from 'axios';
import { authenticatedAPI } from 'services/api/api';


const getProfessionalExperience = async () => {
  try {
    const response = await authenticatedAPI.get(
      `/api/internal/students/me/experiences`
    );
    return response.data;
  } catch (error) {
    return error;
  }
};

const updateProfessionalExperience = async (data) => {
  try {
    const response = await authenticatedAPI.patch(
      `/api/internal/students/me/professional-experience/${data.id}`,
      data
    );
    return response.data;
  } catch (error) {
    return error;
  }
};

const deleteProfessionalExperience = async (experienceId) => {
  try {
    const response = await authenticatedAPI.delete(
      `/api/internal/students/me/professional-experience/${experienceId}`
    );
    return response.data;
  } catch (error) {
    return error;
  }
};

const getProfessionalExperienceAreas = async () => {
  try {
    const response = await authenticatedAPI.options(
      `/api/internal/students/me/professional-experience`
    );
    return response.data;
  } catch (error) {
    return error;
  }
};

const saveProfessionalExperience = async (data) => {


  try {
    const response = await authenticatedAPI.post(
      `/api/internal/students/me/professional-experience`,
      data
    );
    return response.data;
  } catch (error) {
    return error;
  }
};

export {
  deleteProfessionalExperience,
  getProfessionalExperienceAreas,
  updateProfessionalExperience,
  saveProfessionalExperience,
  getProfessionalExperience,
};
