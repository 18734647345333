import React, { memo, useEffect, useState } from 'react';
import { func, object, array, string } from 'prop-types';

import { withStyles } from '@material-ui/core/styles';
import {
  Table,
  TableBody,
  TableCell,
  TablePagination,
  TableRow,
  Paper,
  Checkbox,
} from '@material-ui/core';

import TableToolbar from './TableToolbar';

const styles = (theme) => ({
  root: {
    width: '100%',
    marginTop: theme.spacing.unit * 3,
  },
  table: {
    minWidth: 720,
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  row: {
    cursor: 'pointer',
  },
});

function DataTable(props) {
  const {
    classes,
    loadingState,
    tableData,
    handleDelete,
    handleDetail,
    handleEdit,
    tableTitle,
    DataCells,
    DataTableHead,
    permissions,
  } = props;

  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('name');
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [data, setData] = useState(tableData);

  useEffect(() => {
    setData(tableData);
  }, [tableData]);

  useEffect(() => {
    loadingState(false);
  }, []);

  const getComparisonString = (str) => {
    return str
      .trim()
      .toLowerCase()
      .replaceAll('á', 'a')
      .replaceAll('é', 'e')
      .replaceAll('í', 'i')
      .replaceAll('ó', 'o')
      .replaceAll('ú', 'u')
      .replaceAll('à', 'a')
      .replaceAll('è', 'e')
      .replaceAll('ì', 'i')
      .replaceAll('ò', 'o')
      .replaceAll('ù', 'u')
      .replaceAll('ã', 'a')
      .replaceAll('õ', 'o')
      .replaceAll('â', 'a')
      .replaceAll('ê', 'e')
      .replaceAll('î', 'i')
      .replaceAll('ô', 'o')
      .replaceAll('û', 'u')
      .replaceAll('ç', 'c');
  };

  function comparison(a, b, orderBy) {
    if (order === 'asc') {
      if (orderBy === 'name') {
        let aStr = getComparisonString(a.name);
        let bStr = getComparisonString(b.name);

        return aStr > bStr ? 1 : aStr < bStr ? -1 : 0
      } else if (orderBy === 'macro_area') {
        let aStr = getComparisonString(a.macroArea.label);
        let bStr = getComparisonString(b.macroArea.label);

        return aStr > bStr ? 1 : aStr < bStr ? -1 : 0
      } else if (orderBy === 'published') {
        return (a.published === b.published) ? 0 : a.published ? 1 : -1;
      } else if (orderBy === 'createdAt'){
        let arrDateA = a.createdAt.split('/');
        let arrDateB = b.createdAt.split('/');

        let dateA = new Date(arrDateA[1]+'-'+arrDateA[0]+'-'+arrDateA[2]);
        let dateB = new Date(arrDateB[1]+'-'+arrDateB[0]+'-'+arrDateB[2]);

        return dateA - dateB;
      }
    } else {
      if (orderBy === 'name') {
        let aStr = getComparisonString(a.name);
        let bStr = getComparisonString(b.name);

        return bStr > aStr ? 1 : bStr < aStr ? -1 : 0
      } else if (orderBy === 'macro_area') {
        let aStr = getComparisonString(a.macroArea.label);
        let bStr = getComparisonString(b.macroArea.label);

        return bStr > aStr ? 1 : bStr < aStr ? -1 : 0
      } else if (orderBy === 'published') {
        return (a.published === b.published) ? 0 : a.published ? -1 : 1;
      } else if (orderBy === 'createdAt'){
        let arrDateA = a.createdAt.split('/');
        let arrDateB = b.createdAt.split('/');

        let dateA = new Date(arrDateA[1]+'-'+arrDateA[0]+'-'+arrDateA[2]);
        let dateB = new Date(arrDateB[1]+'-'+arrDateB[0]+'-'+arrDateB[2]);

        return dateB - dateA;
      }
    }
  }
  
  function stableSort(array) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => comparison(a[0], b[0], orderBy));
    return stabilizedThis.map((el) => el[0]);
  }
  
  const handleRequestSort = (property) => {
    const newOrderBy = property;
    let newOrder = 'desc';

    if (orderBy === property && order === 'desc') {
      newOrder = 'asc';
    }

    setOrder(newOrder);
    setOrderBy(newOrderBy);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = [];
      data.map((row) =>
        !row.published || permissions.publish
          ? newSelected.push(row.id)
          : newSelected.push(false)
      );
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleSearch = async (value) => {
    const filteredData = await tableData.filter((item) => {
      const searchRegex = new RegExp(value, 'gi');
      return searchRegex.test(item.name);
    });
    setData(filteredData);
  };

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, data.length - page * rowsPerPage);

  return (
    <Paper className={classes.root}>
      <TableToolbar
        numSelected={selected.length}
        handleDelete={async () => {
          setData(await handleDelete(selected));
          setSelected([]);
        }}
        handleSearch={handleSearch}
        title={tableTitle}
      />
      <div className={classes.tableWrapper}>
        <Table className={classes.table} aria-labelledby="tableTitle">
          <DataTableHead
            numSelected={selected.length}
            order={order}
            orderBy={orderBy}
            onSelectAllClick={handleSelectAllClick}
            onRequestSort={handleRequestSort}
            rowCount={data.length}
          />
          <TableBody>
            {stableSort(data)
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((n) => {
                const isSelected = selected.indexOf(n.id) !== -1;

                return (
                  <TableRow
                    hover
                    onClick={(event) =>
                      event.target.type === 'checkbox' ||
                      event.target.nodeName === 'path' ||
                      event.target.nodeName === 'svg' ||
                      event.target.nodeName === 'BUTTON'
                        ? null
                        : handleDetail(n)
                    }
                    role="checkbox"
                    aria-checked={isSelected}
                    tabIndex={-1}
                    key={n.id}
                    selected={isSelected}
                    className={classes.row}
                  >
                    <TableCell padding="checkbox">
                      <Checkbox
                        checked={isSelected}
                        onClick={() => handleClick(n.id)}
                        disabled={
                          (n.published && !permissions.publish) ||
                          !permissions.delete
                        }
                      />
                    </TableCell>
                    <DataCells
                      loadingState={loadingState}
                      handleEdit={handleEdit}
                      item={n}
                      permissions={permissions}
                    />
                  </TableRow>
                );
              })}
            {emptyRows > 0 && (
              <TableRow style={{ height: 49 * emptyRows }}>
                <TableCell colSpan={5}>
                  {data.length === 0 && (
                    <h3 style={{ textAlign: 'center' }}>
                      Não existem dados para serem mostrados!
                    </h3>
                  )}
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </div>
      <TablePagination
        rowsPerPageOptions={[20, 50, 100]}
        component="div"
        count={data.length}
        rowsPerPage={rowsPerPage}
        page={page}
        backIconButtonProps={{
          'aria-label': 'Página anterior',
        }}
        nextIconButtonProps={{
          'aria-label': 'Próxima página',
        }}
        onChangePage={(event, page) => setPage(page)}
        onChangeRowsPerPage={(event) => setRowsPerPage(event.target.value)}
        labelRowsPerPage="Itens por página:"
      />
    </Paper>
  );
}

DataTable.propTypes = {
  classes: object.isRequired,
  loadingState: func.isRequired,
  tableData: array.isRequired,
  handleDelete: func.isRequired,
  handleDetail: func.isRequired,
  handleEdit: func.isRequired,
  tableTitle: string.isRequired,
  DataCells: func.isRequired,
  DataTableHead: func.isRequired,
  permissions: object.isRequired,
};

export default memo(withStyles(styles)(DataTable));
