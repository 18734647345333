import React, { useContext, useState } from 'react';
import {
  ModalReward,
  BackgroundModalReward,
  SectionRewardLevel,
  SectionButtonLevel,
  Divider,
  ParagraphReward,
  IconReward,
  TitleReward,
  StatusReward,
  ContainerIconReward,
  ContainerStatus,
  HeaderModal,
  TitleModal,
  ButtonCloseModal,
  ButtonSeeReward,
  ButtonCopyLink,
  ButtonStartQuestionnaire,
  AlertQuestionnaire,
} from './styled';
import { ProfileContext } from 'views/Profile/Context/ProfileContext';
import { IconBlocked } from 'assets/img/iconsCurriculum/iconBlocked';
import { IconCurriculumReward } from 'assets/img/iconsCurriculum/iconCurriculumReward';
import { IconProfileReward } from 'assets/img/iconsCurriculum/iconProfileReward';
import { IconGiftReward } from 'assets/img/iconsCurriculum/iconGiftReward';
import { IconCloseModal } from 'assets/img/iconsCurriculum/iconCloseModal';

import CurriculumModalGenerate from 'components/CurriculumModalGenerate';
import defineUrlPublicProfile from 'utils/defineUrlPublicProfile';
import BaseLayoutContext from 'contexts/base-layout';
import LinkIconAlt from 'assets/ComponentIcons/LinkIcon';
import { generatePublicProfileLog } from 'services/student/profile';
import { IoPlayOutline } from 'react-icons/io5';
import { IoDocumentTextOutline } from 'react-icons/io5';
import mergeContextsHelper from 'utils/mergeContextsHelper';
import BehavioralTest from 'views/MyProfile/components/BehavioralTest/BehavioralTest';
import ModalInformation from 'components/informationModal';
import useMediaQuery from 'hooks/useMediaQuery';

const ModalRewards = ({ closeModalReward, gamificationLevel, cardData }) => {
  const {
    universityColor,
    handleCurriculum,
    fetchGamificationData,
  } = useContext(ProfileContext);
  const { metadata, openSnackbar, openModalWithContent } = useContext(
    BaseLayoutContext
  );
  const [generateCurriculumModal, setGenerateCurriculumModal] = useState(false);
  const [curriculumData, setCurriculumData] = useState(null);
  const moreLevelOne = gamificationLevel > 1;
  const moreLevelTwo = gamificationLevel > 2;
  const [controlInformationModal, setControlInformationModal] = useState(false);
  const isMobile = useMediaQuery('(max-width: 768px)');

  const returnColorOne = () => {
    return moreLevelOne ? universityColor : '#606062';
  };

  const openBehavioralQuestionnaire = () => {
    setControlInformationModal(false);
    closeModalReward();
    openModalWithContent(
      mergeContextsHelper(
        <BehavioralTest
          indicator={cardData['behavioral']}
          cardData={cardData}
          cardDataKey={'behavioral'}
          fetchGamificationData={fetchGamificationData}
        />
      ),
      'Questionário de perfil comportamental',
      null,
      () => null,
      false,
      'behavioral'
    );
  };

  const config = {
    modalWidth: 500,
    bubbleText: `Atenção!`,
    ParagraphText: `Recomendamos que você tenha um tempinho dedicado para realizar o seu
    questionário com calma, pois, após realizar a conclusão o seu
    resultado ficará exposto em seu currículo e você só poderá refazê-lo
    daqui <b>6 meses!</b>`,
    buttonConfirmText: 'Estou com tempo, bora lá!',
    buttonConfirmFunction: openBehavioralQuestionnaire,
    buttonCloseX: true,
    style: {
      fontSize: '18px',
      lineHeight: '24px',
      maxWidth: '92%',
    },
  };

  const returnColorTwo = () => {
    return moreLevelTwo ? universityColor : '#606062';
  };

  const returnIconLevelOne = () => {
    if (gamificationLevel <= 1) {
      return <IconBlocked color="#C4C4C4" />;
    }
    if (gamificationLevel > 1) {
      return <IconCurriculumReward color={universityColor} />;
    }
  };

  const returnStatusLevelOne = () => {
    if (gamificationLevel <= 1) {
      return 'Bloqueado';
    }
    if (gamificationLevel > 1) {
      return 'Resgatada';
    }
  };

  const returnIconLevelTwo = () => {
    if (gamificationLevel <= 2) {
      return <IconBlocked color="#C4C4C4" />;
    }
    if (gamificationLevel > 2) {
      return <IconProfileReward color={universityColor} />;
    }
  };

  const returnStatusLevelTwo = () => {
    if (gamificationLevel <= 2) {
      return 'Bloqueado';
    }
    if (gamificationLevel > 2) {
      return 'Resgatada';
    }
  };

  const copyLink = () => {
    const url = `${defineUrlPublicProfile(metadata.university_slug)}/${
      metadata.public_profile
    }`;
    navigator.clipboard.writeText(url);
    openSnackbar('Link copiado com sucesso!', false);
    generatePublicProfileLog();
  };

  return (
    <BackgroundModalReward>
      {generateCurriculumModal && (
        <CurriculumModalGenerate
          isOpen={generateCurriculumModal}
          onClose={() => setGenerateCurriculumModal(false)}
          curriculumData={curriculumData}
          setCurriculumData={setCurriculumData}
          showActions={false}
        />
      )}
      <ModalReward>
        <HeaderModal>
          <ContainerStatus>
            <TitleModal>Minhas Conquistas</TitleModal>
            <TitleReward isParagraph>
              Confira abaixo as recompensas.
            </TitleReward>
          </ContainerStatus>

          <ButtonCloseModal onClick={closeModalReward}>
            <IconCloseModal color={'#4B4B4D'} />
          </ButtonCloseModal>
        </HeaderModal>
        <div className="container">
          <div className="left-side">
            <SectionRewardLevel>
              <SectionButtonLevel>
                <ContainerIconReward>
                  <IconReward>{returnIconLevelOne()}</IconReward>
                  <ContainerStatus>
                    <TitleReward>
                      Nível 1- Currículo profissional e Perfil público{' '}
                      <StatusReward returnColor={returnColorOne}>
                        {returnStatusLevelOne()}
                      </StatusReward>
                    </TitleReward>
                  </ContainerStatus>
                </ContainerIconReward>
              </SectionButtonLevel>
              <ParagraphReward>
                Gere o seu Currículo do Futuro e o seu perfil publico, acesse as
                vagas mais alinhadas ao seu perfil e começar a jornada do
                protagonismo!
              </ParagraphReward>
            </SectionRewardLevel>
          </div>
          <div className="right-side">
            <div className="container-buttons">
              {moreLevelOne ? (
                <ButtonSeeReward
                  universityColor={universityColor}
                  onClick={() => handleCurriculum()}
                >
                  <IoDocumentTextOutline size={20} />
                  {isMobile ? 'Currículo' : 'Visualizar currículo'}
                </ButtonSeeReward>
              ) : null}
              {gamificationLevel > 1 && (
                <ButtonCopyLink
                  universityColor={universityColor}
                  onClick={() => copyLink()}
                >
                  <LinkIconAlt size={18} color={universityColor} />
                  {isMobile ? 'Link público' : 'Gerar link público'}
                </ButtonCopyLink>
              )}
            </div>
          </div>
        </div>
        <Divider />
        <div className="container">
          <div className="left-side">
            <SectionRewardLevel positionSection="bottom">
              <SectionButtonLevel>
                <ContainerIconReward>
                  <IconReward>{returnIconLevelTwo()}</IconReward>
                  <ContainerStatus>
                    <TitleReward>
                      Nível 2- Perfil campeão e Questionário comportamental{' '}
                      <StatusReward returnColor={returnColorTwo}>
                        {returnStatusLevelTwo()}
                      </StatusReward>
                    </TitleReward>
                  </ContainerStatus>
                </ContainerIconReward>
              </SectionButtonLevel>
              <ParagraphReward>
                Agora você tem um Perfil Campeão e poderá realizar o
                questionário de competências comportamentais. Seu currículo está super
                atraente e suas chances de conseguir seu lugar no mercado de
                trabalho aumentaram significativamente!
              </ParagraphReward>
            </SectionRewardLevel>
          </div>
          {controlInformationModal && (
            <ModalInformation
              universityColor={universityColor}
              modalOpenState={controlInformationModal}
              config={config}
              buttonConfirmFunction={config.buttonConfirmFunction}
              closeModalFunction={() => setControlInformationModal(false)}
            />
          )}
          <div className="right-side">
            {gamificationLevel > 0 && (
              <div className="container-buttons">
                <ButtonStartQuestionnaire
                  disabled={cardData['behavioral'].is_enabled === false}
                  universityColor={universityColor}
                  onClick={() => setControlInformationModal(true)}
                >
                  <IoPlayOutline size={20} />
                  Realizar Questionário
                </ButtonStartQuestionnaire>
                {cardData['behavioral'].is_done === true && (
                  <AlertQuestionnaire>
                    Este questionário pode ser refeito a cada 6 meses.
                  </AlertQuestionnaire>
                )}
              </div>
            )}
          </div>
        </div>
      </ModalReward>
    </BackgroundModalReward>
  );
};

export default ModalRewards;
