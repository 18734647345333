import React, { Fragment, useState, useEffect } from 'react';
import {
  HeaderContainer,
  HeaderInfos,
  ListContainer,
  ButtonAction,
  PaginationStyle,
} from '../style';
import academicInnovationApi from 'views/Backoffice/academicInnovation/services/academicInnovationApi';
import AssociateTagForm from '../../Forms/AssociateTagForm';
import { useFormContext } from 'views/Backoffice/academicInnovation/contexts';
import { useDebounce } from 'hooks/useDebounce';
import ButtonWithMenu from 'components/ModalShowEvidence/ButtonWithMenu';
import Pagination from 'components/CustomPagination';
import {
  searchQuery,
  searchPage,
} from 'views/Backoffice/academicInnovation/Screens/Tabs/utils/queryString';
import RegisterTagMainForm from '../../Forms/registerTagMainForm';
import ModalViewAssociation from '../../Modal';
import GenericMessage from 'components/GenericMessage';
import { CircularProgress } from '@material-ui/core';

export default function MainComponent({ countMainTag, setCountMainTag }) {
  const {
    queryFilter,
    setCurrentFormToShow,
    searchTeam,
    mainTags,
    setMainTags,
    limit,
    setLimit,
    isListEmpty,
    setListEmpty,
    isLoading,
    setIsLoading,
    currentPage,
    setCurrentPage,
  } = useFormContext();
  const [viewAssociationModal, setViewAssociationModal] = useState(false);
  const [currentItemView, setCurrentItemView] = useState({});

  const listMainTags = async (searchTeam) => {
    setIsLoading(true);
    const { results, count } = await academicInnovationApi.getMainTagsList(
      searchTeam
    );
    setMainTags(results);
    setCountMainTag(count);
    if (results.length === 0) {
      setListEmpty(true);
    } else {
      setListEmpty(false);
    }
    setIsLoading(false);
  };

  const handleSpanPopUpClick = (item) => {
    setViewAssociationModal(true);
    setCurrentItemView(item);
  };

  const menusButtons = [
    {
      name: 'Associar',
      onChange: (_, item) =>
        setCurrentFormToShow(<AssociateTagForm item={item} />),
    },
    {
      name: 'Visualizar',
      onChange: (_, item) => {
        handleSpanPopUpClick(item);
      },
    },

    {
      name: 'Editar',
      onChange: (_, item) =>
        setCurrentFormToShow(<RegisterTagMainForm selectedTag={item.id} />),
    },
  ];

  useEffect(() => {
    const timeout = setTimeout(() => {
      const search = searchQuery(limit, queryFilter, searchTeam);
      listMainTags(search);
      setCurrentPage(1);
    }, 600);
    return () => clearTimeout(timeout);
  }, [searchTeam]);

  const handlePageChange = async (page) => {
    const newPage = searchPage(page, limit, queryFilter, searchTeam);
    await listMainTags(newPage);
    setCurrentPage(page);
  };

  const handleLimitChange = async (newLimit) => {
    const query = searchQuery(newLimit, queryFilter, searchTeam);
    setCurrentPage(1);
    await listMainTags(query);
    setLimit(newLimit);
  };

  return (
    <>
      <Fragment>
        {viewAssociationModal && (
          <ModalViewAssociation
            currentItemView={currentItemView}
            setViewAssociationModal={setViewAssociationModal}
          />
        )}
        {isLoading ? (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              margin: '50px',
            }}
          >
            <CircularProgress style={{ color: '#009291' }} />
          </div>
        ) : countMainTag === 0 ? (
          <GenericMessage
            title="Nenhuma TAG Principal foi encontrada"
            subtitle="Cadastre novas TAGs no botão acima. "
          />
        ) : (
          <>
            <HeaderContainer>
              <HeaderInfos>
                <span className="header-name-tag">NOME DA TAG</span>
                <span className="header-associations"> ASSOCIAÇÕES</span>
              </HeaderInfos>
            </HeaderContainer>
            {mainTags.map((item, index) => (
              <ListContainer key={index}>
                <span className="list-name-tag">{item.name}</span>
                <span className="list-associations">
                  {item.associated_tags_quantity}
                </span>
                <ButtonAction>
                  <ButtonWithMenu
                    item={item}
                    insideMenus={menusButtons}
                    menuLabel="Ações"
                    className="custom-width"
                    MinWidth={'6rem'}
                  />
                </ButtonAction>
              </ListContainer>
            ))}
          </>
        )}
      </Fragment>
      <PaginationStyle>
        <Pagination
          className="pagination-bar"
          currentPage={currentPage}
          totalCount={countMainTag}
          onPageChange={handlePageChange}
          setLimit={handleLimitChange}
          showLimit={countMainTag > 10}
          setCurrentPage={setCurrentPage}
          pageRef={null}
          limit={limit}
        ></Pagination>
      </PaginationStyle>
    </>
  );
}
