import React, { Fragment } from 'react';
import { func, string, arrayOf, shape } from 'prop-types';

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
import { Grid, Paper } from '@material-ui/core';

import GridItem from 'components/Grid/GridItem';

import dashboardStyle from 'assets/jss/material-dashboard-pro-react/views/dashboardStyle';
import mergeContextsHelper from 'utils/mergeContextsHelper';

const ContentItems = (props) => {
  const {
    classes,
    openModalWithContent,
    welcomeMessage,
    data,
    getCertificateData,
  } = props;

  const propsCopy = { ...props };
  delete propsCopy.classes;
  return (
    <Fragment>
      {welcomeMessage && (
        <Grid item xs={12}>
          <Paper className={classes.welcomeMessage} elevation={0}>
            <h4 className={classes.description}>{welcomeMessage}</h4>
          </Paper>
        </Grid>
      )}

      {/* Items */}
      <div className={classes.containerThumbHolder}>
        {data.map((video) => {
          return (
            <div>
              <figure className={classes.videoThumbHolder}>
                <button
                  type="button"
                  className={classes.videoTrigger}
                  onClick={() =>
                    openModalWithContent(
                      mergeContextsHelper(
                        <video.modalContent
                          {...props}
                          videoId={video.id}
                          Form={video.form}
                        />
                      ),
                      video.title,
                      null,
                      getCertificateData
                    )
                  }
                >
                  <img
                    src={`http://i3.ytimg.com/vi/${video.id}/hqdefault.jpg`}
                    alt={video.title}
                    className={classes.imageFluid}
                  />
                </button>
                <figcaption style={{ maxWidth: '400px' }}>
                  {video.description || video.title}
                </figcaption>
              </figure>
            </div>
          );
        })}
      </div>
    </Fragment>
  );
};

ContentItems.defaultProps = {
  getCertificateData: undefined,
  welcomeMessage: '',
};

ContentItems.propTypes = {
  getCertificateData: func,
  classes: shape({}).isRequired,
  openModalWithContent: func.isRequired,
  data: arrayOf({}).isRequired,
  welcomeMessage: string,
};

export default withStyles(dashboardStyle)(ContentItems);
