import styled, { keyframes } from 'styled-components';
export const Container = styled.div`
  width: 100vw;
  height: 100vh;
  margin: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: ${(props) => props.color};

  .form {
    width: 100%;
  }

  .form span {
    display: flex;
    height: 13px;
  }

  .form div:first-child {
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .form .button-login-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  @media (max-width: 850px) {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  @media (max-height: 600px) {
    .form div:first-child {
      margin-top: 13px;
      margin-bottom: 20px;
    }
  }
`;

export const Divider = styled.div`
  width: 100%;
  height: 2px;
  background-color: #e0e0e0;

  margin-top: 30px;
`;

export const ContainerAllLogos = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  width: 370px;

  > div {
    margin-left: 10px;
  }

  @media (max-width: 850px) {
    margin-top: 25px;
    align-items: center;
  }
`;

export const ContainerImage = styled.div`
  background-image: url(${(props) => props.image});
  background-size: cover;
  height: 100%;
  width: 100%;

  @media (max-width: 850px) {
    background-position: center;
  }
`;

export const BoxLogos = styled.div`
  width: 56%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  position: absolute;
  left: 0;

  @media (max-width: 850px) {
    width: 100%;
    position: unset;
    height: 17%;
  }
`;

export const ImgArrow = styled.img`
  width: 24px;
  height: 24px;
  margin-bottom: 20px;
  color: ${(props) => props.universityColor || '#009291'};

  cursor: pointer;

  @media (max-width: 400px) {
    margin-bottom: 0px;
    margin-top: 20px;
  }
`;

export const ContainerButtonBack = styled.button`
  cursor: pointer;
  border: none;
  background-color: transparent;
  ${(props) =>
    props.isUniversityLogin &&
    `
  position:absolute;
  bottom: 80%;
  @media screen and (max-width: 850px) {
    bottom:90% !important;
  }
  `}
`;

export const ContainerButtonArrow = styled.div`
  margin-left: 120px;

  @media (max-width: 850px) {
    margin-left: 0;
  }
`;

export const ContainerInCenter = styled.div`
  display: flex;
  align-items: ${(props) => (props.isCenter ? 'center' : 'flex-start')};
  justify-content: center;
  flex-direction: column;
  width: 100%;

  .infoIcon {
    width: 82px;
    height: 82px;
    border-radius: 50%;
    background: #00929133;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 24px;
  }

  img {
    width: 100%;
    max-width: ${(props) => props.logoWidth ?? '130px'};
    height: 100%;
    margin-bottom: 30px;

    @media (max-height: 600px) {
      margin-bottom: ${(props) => (props.isCompany ? '0px' : '60px')};
      max-width: ${(props) => (props.isCompany ? '130px' : props.logoWidth)};
    }
  }

  @media (max-width: 850px) {
    display: ${(props) => (props.isStudent ? 'flex' : '')};
    padding-top: 0;

    img {
      width: 121px;
      margin: 10px 0;
    }
    align-items: flex-start !important;
  }

  @media (max-width: 850px) and (max-height: 671px) {
    img {
      width: 45%;
      margin: 3px;
    }
  }

  @media (min-width: 850px) and (max-height: 515px) {
    img {
      width: 45%;
    }
  }
`;

export const FragmentContainer = styled.div`
  margin: ${(props) => props.marginFragment || '0'};

  .infoContent {
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    align-content: center;
    width: 368px;
    @media screen and (max-width: 530px) {
      width: 60%;
    }
  }
  .infoIcon {
    width: 82px;
    height: 82px;
    border-radius: 50%;
    background: #00929133;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 24px;
  }

  .infoTitle {
    font-weight: 700;
    font-size: 22px;
    line-height: 26px;
    color: #606062;
    font-family: 'Inter', sans-serif;
  }
  .infoBody {
    p {
      font-weight: 400;
      font-size: 16px;
      line-height: 18px;
      color: #606062;
      font-family: 'Inter', sans-serif;
      margin: 0px;
    }
    p + p {
      margin-top: 1.25rem;
    }
  }

  .expired-text {
    font-size: 16px !important;
    font-weight: 400;
    font-family: 'Inter', sans-serif;
    width: ${(props) => (props.isChooseUser ? '289px' : '344px')};
  }
`;

export const Titles = styled.div`
  color: ${(props) => (props.isLogo ? '#fff' : props.color || '#606062')};
  font-size: ${(props) => (props.isLogo ? '36px' : '22px')};
  margin-top: 0px;
  text-align: ${(props) => (props.isChooseUser ? 'center' : 'start')};
  font-weight: ${(props) => (props.isPassword ? '0' : '700')};
  width: ${(props) => (props.isChooseUser ? '289px' : '344px')};

  span {
    line-height: 26px;
  }

  strong {
    margin-bottom: ${(props) => props.marginBottom || '15px'};
    line-height: ${(props) => props.lineHeight || '43px'};
  }
  display: flex;
  flex-direction: column;

  @media (max-width: 850px) {
    font-size: 20px;
    margin-top: 10px;
    max-width: ${(props) => (props.isLogo ? '300px' : '100%')};
    width: 100%;
    text-align: start;
    order: 1;

    strong {
      margin-bottom: 15px;
      line-height: ${(props) => props.lineHeightMobile};
      text-align: start;
      font-size: 22px;
    }

    .intern-text {
      font-size: 1em !important;
      line-height: 0.8em;
      height: 45px;
      display: flex;
      align-items: center;
      flex-direction: column;
    }
  }
  .subtitle {
    font-size: 16px !important;
    font-weight: 400;
    font-family: 'Inter', sans-serif;
    line-height: 18px;
  }

  @media (max-height: 600px) {
    font-size: ${({ presentation }) => presentation && '18px'};
    margin-top: ${({ presentation }) => presentation && '10px'};
  }
`;

export const ContainerTitles = styled.div`
  display: flex;
  flex-direction: column;
  gap: 3px;
  color: ${(props) => props.color};

  p {
    font-size: 25px;
    font-weight: 700;
    ${(props) => props.isRecovery && `color:#606062`}
  }

  @media (max-width: 850px) {
    text-align: center;
  }
`;

export const ContainerSubTitles = styled.div`
  display: flex;
  flex-direction: column;
  gap: 3px;

  p {
    font-size: 16px;
    font-weight: 400;
    color: #606062;
    font-family: 'Inter' sans-serif;
  }

  @media (max-width: 850px) {
    text-align: center;
  }
`;

export const Paragraph = styled.p`
  margin: 0;
  color: ${(props) => (props.isFooter ? '#4B4B4D' : '#fff')};
  font-size: ${(props) => (props.isFooter ? '12px' : '18px')};
  line-height: 26px;

  @media (max-width: 850px) {
    display: ${(props) => (props.isVisibleOnMobile ? 'block' : 'none')};
    order: 2;
  }
`;

export const Footer = styled.span`
  color: #4b4b4d;
  font-size: 12px;
  line-height: 26px;
  margin: 0 !important;

  @media (max-width: 1480px) {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 90%;
  }
`;

export const ButtonRegister = styled.button`
  background-color: transparent;
  font-weight: 700;
  border: none;
  color: ${(props) => props.color};

  cursor: pointer;

  font-size: 12px;
`;

export const ContainerLogin = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;

  background-color: #fff;
  border-bottom-left-radius: 70px;
  border-top-left-radius: 70px;
  width: ${({ recoverPassword }) => (recoverPassword ? '' : '50%')};
  height: 100%;
  position: absolute;
  right: 0;
  svg {
    cursor: pointer;
  }
  padding: 40px;
  
  @media (max-width: 850px) {
    width: 100%;

    border-bottom-left-radius: 0px;
    border-top-right-radius: 30px;
    border-top-left-radius: 30px;
    justify-content: flex-start;

    position: absolute;
    height: 85%;
    bottom: 0;
    padding-top: 45px;
    ${({ recoverPassword }) =>
      recoverPassword ? 'top: 0; padding: 30px;' : ''};
  }

  @media (max-height: 500px) {
    bottom: unset;
    top: 0;
    height: 100%;
  }

  @media (max-height: 720px) and (max-width: 851px) {
    height: 95%;
    transition: 0.5s;
  }

  @media (max-height: 575px) and (max-width: 851px) {
    height: 100%;
    transition: 0.5s;
  }

  @media (max-height: 667px) {
    /* height: ${({ contentImage }) => (contentImage ? '79%' : '100%')}; */
    border-top-right-radius: ${({ contentImage }) =>
      contentImage ? '50px' : '0px'};
    border-top-left-radius: ${({ contentImage }) =>
      contentImage ? '50px' : '0px'};
    transition: 0.5s;
  }
  @media (max-height: 546px) {
    border-top-right-radius: 0px;
    border-top-left-radius: 0px;
    transition: 0.5s;
  }
`;

export const ContainerForgotPassword = styled(ContainerLogin)`
  width: ${({ student }) => (student ? '100%' : '50%')};
  height: ${({ student }) => (student ? '100%' : '63')};

  @media (max-width: 850px) {
    width: 100%;
  }

  @media (max-width: 850px) and (max-height: 708px) {
    height: ${({ contentImage }) => (contentImage ? '70%' : '100%')};
    transition: 0.5s;
  }
`;

export const ButtonPassword = styled.button`
  border: none;
  background-color: transparent;
  color: ${(props) => props.color};
  margin-top: ${(props) => props.marginTop};
  cursor: pointer;
`;

export const ButtonLogin = styled.button`
  width: 100%;
  max-width: 370px;
  height: 50px;
  border: none;
  border-radius: 6px;
  font-size: 18px;
  color: #fff;
  font-weight: 700;
  background-color: ${(props) => props.color ?? '#009291'};

  padding: 12px 0;

  display: flex;
  justify-content: center;
  align-items: center;

  margin-top: 16px;

  cursor: pointer;

  @media (max-width: 850px) {
    width: 100%;
    max-width: 370px;
    margin-top: 20px;
  }
`;

export const RecoverPassword = styled.a`
  color: ${(props) => props.color} !important;
  font-size: 13px;
  margin-top: 13px;
`;

export const ContainerButtonFooter = styled.div`
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

export const CreateAccount = styled.button`
  width: 100%;
  max-width: 185px;
  padding: 9px 0;

  font-size: 1.25rem;
  font-weight: 700;
  color: #009291;
  border: 1px solid #009291;
  border-radius: 5px;
  background-color: transparent;
`;

export const Checkbox = styled.div`
  width: 14px;
  height: 14px;
  cursor: pointer;

  border-radius: 50%;

  margin-right: 6px;

  background-color: ${(props) => props.color};
`;

export const ContainerWorkalove = styled.div`
  display: flex;
  justify-content: center;

  position: absolute;
  bottom: 0;

  @media (max-width: 850px) and (max-height: 805px) {
    position: unset;
  }

  @media (min-width: 850px) and (max-height: 541px) {
    position: unset;
  }
`;

export const ContainerChooseUser = styled.div`
  margin-top: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 31px;
  width: 100%;
`;

export const ButtonUser = styled.a`
  width: 120px;
  height: 120px;

  background-color: transparent;

  border: 1px solid #4b4b4d;
  border-radius: 10px;

  cursor: pointer;
`;

export const ContentContainerUser = styled.div`
  width: 100%;
  height: 100%;

  display: flex;
  align-items: center;
  justify-content: center;

  flex-direction: column;
`;

export const ImgUser = styled.img`
  width: 50px;
  height: 50px;
  margin-bottom: 10px;
`;

export const ParagraphUser = styled.p`
  font-size: 14px;
  font-weight: bold;
  color: ${(props) => props.color};
  margin: 0;
`;

export const ParagraphEmail = styled.p`
  font-size: 22px;
  color: #4b4b4d;
  line-height: 29px;
`;

export const ContainerTitlesEmail = styled.div`
  width: 70%;
  margin: 40px 0 120px 120px;

  @media (max-width: 850px) {
    margin-left: 0;
    margin-top: 70px;
    margin: ${({ recoverPassword }) =>
      recoverPassword ? '40px 0 0 0' : '70px 0 0 0'};
  }
`;

export const ContainerParagraphs = styled.div`
  margin-top: 16px;

  @media (max-width: 850px) {
    margin-left: 0;
    width: 140%;
  }
`;

export const CircleEmail = styled.div`
  position: relative;

  width: 83px;
  height: 83px;

  display: flex;
  align-items: center;
  justify-content: center;

  border-radius: 50%;
  background-color: ${(props) =>
    props.universityColor ? props.universityColor : '#b5e7e6'};
  position: relative;

  svg {
    z-index: 2;
    cursor: default;
  }

  &:before {
    content: '';
    display: block;
    position: absolute;
    width: 104%;
    height: 104%;
    background-color: #fff;
    opacity: 0.9;
    z-index: 1;
    border-radius: 50%;
  }

  @media (min-width: 850px) {
    margin-top: 40px;
  }
`;

export const ImgEmail = styled.img`
  width: 36px;
  height: 36px;
`;

export const ContainerParagraphLogin = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  margin-top: 30px;
`;

export const Box = styled.div`
  width: calc(100% - 259px);
  height: 100%;
  padding-top: 25px;
  padding-bottom: 25px;
  margin-left: auto;

  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 960px) {
    width: 100%;
  }
`;

export const ContainerWal = styled.div`
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.1);
  ${(props) => (props.isOpen ? `display: block;` : `display: none;`)}

  display: flex;
  align-items: center;
  justify-content: center;

  position: fixed;
  left: 0;
  top: 0;
  z-index: 2000;
`;

export const ContainerButton = styled.div`
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Content = styled.div`
  width: 100%;
  max-width: 428px;

  background-color: #ffffff;
  border-radius: 10px;
  padding: 39px;

  position: relative;
`;

export const GreenButton = styled.button`
  width: 100%;
  padding: 11px;
  max-width: ${(props) => props.widthButton};

  border-radius: 4px;

  font-size: 14px;
  font-weight: 500;
  text-align: center;
  color: #ffffff;
  background-color: #009291;
  margin-top: ${(props) => props.marginTopButton || '36px'};
  cursor: pointer;

  border: none;
`;

export const WrapperMessage = styled.div`
  width: 100%;

  font-size: ${(props) => props.fontMessage};
  font-weight: 400;
  color: #606062;
  line-height: ${(props) => props.lineHeigthMessage};
  text-align: ${(props) => props.positionMessage};

  padding: 22px 0;
`;

export const WrapperTitle = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;

  .border-image {
    width: 76px;
    height: 76px;
    background-color: #ebebeb;
    border: 2px solid #009291;
    border-radius: 200px;

    display: flex;
    align-items: center;
    justify-content: center;
  }

  .border-image img {
    width: 100%;
    height: 100%;
    margin-bottom: -3px;
  }
`;

export const BubbleDialog = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  background-color: #009291;
  margin-left: 1.5em;
  border-radius: 4px;
  width: ${(props) => props.widthBubble};
  height: ${(props) => props.heightBubble};
  position: relative;

  ::before {
    content: '';
    width: 0px;
    height: 0px;
    position: absolute;

    border-left: 4.5px solid transparent;
    border-right: 4.5px solid #009291;
    border-top: 4.5px solid #009291;
    border-bottom: 4.5px solid transparent;
    border-radius: 1px;
    left: -4px;
    top: 13px;
    transform: rotate(-135deg);
  }

  span {
    font-family: 'Roboto', sans-serif;
    font-weight: 700;
    font-size: 20px;
    color: #ffffff;
    text-align: center;
  }
`;
export const ContainerLabel = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const CreateAccountDiv = styled.div`
  margin-top: 40px;

  @media (max-height: 650px) {
    margin-top: ${(props) => (props.isCompany ? '0.5em' : '40px')};
  }

  @media (max-width: 768px) {
    margin-top: 0.5em;
  }
  width: 100%;
  justify-content: center;
  .divider {
    display: flex;
    align-items: center;
    span {
      font-size: 18px;
      font-weight: 400;
      font-family: 'Inter', sans-serif;
      margin: 0px 12px;
    }
    hr {
      background-color: #e0e0e0;
      height: 1px;
      flex: 1;
    }
  }
`;

export const ButtonOutlined = styled.button`
  width: 210px;
  max-width: 100vw;
  height: 48px;
  border: solid 1px;
  border-color: ${(props) => props.color};
  color: ${(props) => props.color};
  border-radius: 6px;
  font-size: 18px;
  font-weight: 700;
  background-color: #fff;
  padding: 12px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 40px auto 0px auto;
  transition: all 0.2s;
  cursor: pointer;

  @media (max-width: 850px) {
    width: 100%;
    max-width: 210px;
    margin-top: ${(props) => (props.isCompany ? '5px' : '0px')};
  }
  &:hover {
    filter: brightness(120%);
  }

  @media (max-height: 650px) {
    margin: 0.2em auto 0px auto;
    height: 40px;
  }
`;

export const CaptchaContainer = styled.div`
  margin-top: ${({ marginTop }) => marginTop};

  margin-bottom: ${({ marginBottom}) => marginBottom};

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media (max-height: 656px) {
    margin-top: ${({ marginTop }) => marginTop};

    margin-bottom: ${({ marginBottomResponsive}) => marginBottomResponsive};

    #captchaDiv {
      margin-bottom: 37px;
    }
  }
`;

export function CaptchaSkeleton({ show }) {
  return show ? <ContentSkeleton /> : <></>;
}
const shimmerAnimation = keyframes`
  100% { background-position: -100% 0}
`;

const ContentSkeleton = styled.div`
  height: 74px;
  width: 300px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;

  background-color: #d9d6d6;
  background-image: linear-gradient(
    120deg,
    #d9d6d6 30%,
    #f0f0f0 38%,
    #f0f0f0 40%,
    #d9d6d6 48%
  );
  background-repeat: no-repeat;
  background-size: 200% 100%;
  background-position: 100% 0;
  animation: ${shimmerAnimation} 1.2s linear infinite;
  border-radius: 5px;
  margin: 30px 0;
`;
