import { Form } from '@unform/web';
import React, { Fragment, useContext, useEffect, useState } from 'react';
import SearchableSelectUn from '../form/searchableSelect';
import Input from '../../../../StaffPages/Opportunities/Components/Form/input';
import { jobBenefits, jobType, modalityType, shiftOptions } from './useCases/SelectOptions';
import InputDesc from '../../../../StaffPages/Opportunities/Components/Form/inputDesc';
import Header from '../header';
import { FooterForm, InputDescNewJobText } from './style';
import { getUnits } from '../../services';

import * as Yup from 'yup';
import { schemaValidationStepTwo, schemaValidationStepTwoWithUF } from './useCases/schemaValidation';
import { NextButton, PrevsButton } from '../../../Opportunities/Styles/formStyled';
import { JobOfferContext } from '../../context/jobOfferContext';
import CepAddressInput from 'components/CepAddressInput';

export default function StepTwoJobOffer() {
  const {
    nextJobOfferStep,
    setFormValues,
    data,
    prevsJobOfferStep,
    setCacheContext,
    stepTwoRef,
    isMaster,
    setSelectedUniversities,
  } = useContext(JobOfferContext);

  const [showAddress, setShowAddress] = useState(false);
  const [units, setUnits] = useState([]);
  const [currentSchema, setCurrentSchema] = useState(schemaValidationStepTwo);
  const [selectedShift, setSelectedShift] = useState([]);
  const [selectedApplication, setSelectedApplication] = useState([]);

  useEffect(() => {
    if (data?.application_type && data.application_type.length > 0) {
      setSelectedApplication(data.application_type);
      stepTwoRef.current.setFieldValue(
        'application_type',
        data.application_type,
      );
    }
  }, [data]);

  const isShiftSelected = (selectedOptions, shift) => {
    return selectedOptions.some((option) => option?.value === shift);
  };

  const handleShiftSelect = (selectedOption) => {
    const indifferentSelected = isShiftSelected(selectedOption, 'indifferent');
    const morningSelected = isShiftSelected(selectedOption, 'morning');
    const afternoonSelected = isShiftSelected(selectedOption, 'afternoon');
    const nightSelected = isShiftSelected(selectedOption, 'night');

    let shifts;

    if (
      indifferentSelected &&
      (morningSelected || afternoonSelected || nightSelected)
    ) {
      shifts = [{ label: 'Indiferente', value: 'indifferent' }];
    } else {
      if (morningSelected && afternoonSelected && nightSelected) {
        shifts = [{ label: 'Indiferente', value: 'indifferent' }];
      } else {
        shifts = selectedOption;
      }
    }

    const indifferentShift = shifts.find(
      (shift) => shift.value === 'indifferent',
    );
    if (indifferentShift) {
      shifts = [indifferentShift];
    }

    setSelectedShift(shifts);
    stepTwoRef.current.setFieldValue('work_shift', shifts);
  };

  useEffect(() => {
    if (data?.shift) {
      let shifts = data.shift
        .map((shiftValue) => {
          const shiftOption = shiftOptions.find(
            (shift) => shift.value === shiftValue,
          );
          return shiftOption
            ? { label: shiftOption.label, value: shiftOption.value }
            : null;
        })
        .filter(Boolean);

      const indifferentSelected = isShiftSelected(shifts, 'indifferent');
      const morningSelected = isShiftSelected(shifts, 'morning');
      const afternoonSelected = isShiftSelected(shifts, 'afternoon');
      const nightSelected = isShiftSelected(shifts, 'night');

      if (
        indifferentSelected &&
        (morningSelected || afternoonSelected || nightSelected)
      ) {
        shifts = [{ label: 'Indiferente', value: 'indifferent' }];
      } else {
        if (morningSelected && afternoonSelected && nightSelected) {
          shifts = [{ label: 'Indiferente', value: 'indifferent' }];
        }
      }
      const indifferentShift = shifts.find(
        (shift) => shift.value === 'indifferent',
      );
      if (indifferentShift) {
        shifts = [indifferentShift];
      }

      setSelectedShift(shifts);
      stepTwoRef.current.setFieldValue('work_shift', shifts);
    }
  }, [data]);


  const handleSelectModality = (e) => {
    const value = e.value;
    setFormValues({ modality_type: value });
    if (value === 'hybrid' || value === 'presential') {
      setShowAddress(true);
    } else {
      setFormValues({ zip_code: null });
      stepTwoRef.current.setFieldValue('zip_code', null);
      setShowAddress(false);
    }
  };

  useEffect(() => {
    if (showAddress) {
      return setCurrentSchema(schemaValidationStepTwoWithUF);
    }
    setCurrentSchema(schemaValidationStepTwo);
  }, [showAddress]);


  useEffect(() => {
    if (data.enabled_just_for_unities) {
      stepTwoRef.current.setFieldValue(
        'enabled_just_for_unities',
        units.find((item, index) => {
          return item.value === data.enabled_just_for_unities[index];
        }),
      );
    }

    if (data.modality_type && data.modality_type !== 'remote' && data.modality_type?.value !== 'remote') {
      setShowAddress(true);
    }else{
      setShowAddress(false);
    }
  }, [data, units]);

  useEffect(() => {
    const getUnitsEffect = async () => {
      const response = await getUnits();

      setUnits(response);
      setCacheContext((prevsState) => [
        {
          ...prevsState,
          ...units,
        },
      ]);
    };

    getUnitsEffect();
  }, []);

  function handleShift(data) {
    const getShiftOptions = (shifts) =>
      shifts
        .map((item) => shiftOptions.find((option) => option?.value === item))
        .filter(Boolean);

    const verifyData = getShiftOptions(data?.shift || []);

    const isAllShiftsSelected = (shifts) => {
      const values = shifts?.map((option) => option?.value);
      return (
        values?.includes('morning') &&
        values?.includes('afternoon') &&
        values?.includes('night')
      );
    };

    if (isAllShiftsSelected(verifyData)) {
      stepTwoRef.current.setFieldValue('shift', [
        { value: 'indifferent', label: 'Indiferente' },
      ]);
    } else {
      stepTwoRef.current.setFieldValue('shift', verifyData);
    }

    if (verifyData?.length === 0) {
      const originalShiftOptions = getShiftOptions(data?.shift || []);

      if (isAllShiftsSelected(originalShiftOptions)) {
        stepTwoRef.current.setFieldValue('shift', [
          { value: 'indifferent', label: 'Indiferente' },
        ]);
      } else {
        stepTwoRef.current.setFieldValue('shift', data?.shift);
      }
    }
  }

  useEffect(() => {
    handleShift(data);
  }, []);

  const handleSubmit = async (data) => {
    stepTwoRef.current.setErrors({});

    let newData = data;

    if (data.shift.includes('indifferent')) {
      delete data.shift;
      newData = {
        ...data,
        shift: ['indifferent'],
      };
    }

    try {
      await currentSchema.validate(newData, {
        abortEarly: false,
      });
      setFormValues(newData);
      nextJobOfferStep();
    } catch (err) {
      const validationErrors = {};
      if (err instanceof Yup.ValidationError) {
        err.inner.forEach((error) => {
          validationErrors[error.path] = error.message;
        });
        stepTwoRef.current.setErrors(validationErrors);
      }
    }
  };

  useEffect(() => {
    if (selectedApplication.length > 1) {
      const lastOption = selectedApplication[selectedApplication.length - 1];
      setSelectedApplication([lastOption]);
      stepTwoRef.current.setFieldValue('application_type', [lastOption]);
    }
  }, [selectedApplication]);

  const handleApplicationTypeSelect = (options) => {
    setSelectedApplication(options);
  };

  return (
    <div>
      <Form ref={stepTwoRef} onSubmit={handleSubmit} initialData={data}>
        <Header
          header="Tipo de Vaga*"
          subTitle="Selecione qual tipo de sua vaga."
        />
        <SearchableSelectUn
          name="application_type"
          options={jobType}
          isMulti
          value={selectedApplication}
          onChange={handleApplicationTypeSelect}
          isCleareble
        />

        <Header header="Turno*" subTitle="Selecione o turno de sua vaga." />
        <SearchableSelectUn
          name="shift"
          options={shiftOptions}
          placeholder="Selecione"
          isMulti={true}
          value={selectedShift}
          onChange={handleShiftSelect}
          isCleareble
        />

        <Header
          header="Quantidade de Vagas Disponíveis"
          subTitle="Indique aqui a quantidade de vagas."
        />
        <Input name="quantity" type="number" min={1} />

        {units.length ? (
          <Fragment>
            <Header
              header={
                isMaster
                  ? 'Instituição que deseja publicar'
                  : 'Unidade/Polo que deseja publicar'
              }
              subTitle={
                isMaster
                  ? 'Selecione a instituição em que deseja publicar a vaga'
                  : 'Selecione a unidade/polo em que você deseja publicar a vaga.'
              }
            />
            {isMaster ? (
              <SearchableSelectUn
                name="enabled_just_for_universities"
                options={units}
                isMulti
                isClearable
                onChange={(e) => setSelectedUniversities(e)}
              />
            ) : (
              <SearchableSelectUn
                name="enabled_just_for_unities"
                options={units}
                isMulti
                isCleareble
              />
            )}
          </Fragment>
        ) : null}
        <Header
          header="Benefícios"
          subTitle="Selecione os principais benefícios."
        />
        <SearchableSelectUn
          name="perks_offered"
          options={jobBenefits}
          isMulti
          isCleareble
        />
        <InputDescNewJobText>
          <InputDesc
            name="another_perks"
            label="Detalhe mais sobre seu programa de benefícios"
          />
        </InputDescNewJobText>
        <Header
          header="Modalidade de Trabalho*"
          subTitle="Selecione a modalidade específica para sua vaga."
        />
        <SearchableSelectUn
          name="modality_type"
          options={modalityType}
          onChange={handleSelectModality}
        />
        {showAddress && (
          <CepAddressInput
            formRef={stepTwoRef}
            isFromJobOffer={true}
            defaultValues={{
              zip_code: data.zip_code,
              logradouro: data.address,
              address_number: data.address_number,
              address_complement: data.address_complement,
              bairro: data.district,
              uf: data.state,
              localidade: typeof data?.city === 'object' ? data.city?.label : data.city,
            }}
          />
        )}
        <FooterForm>
          <PrevsButton onClick={prevsJobOfferStep} />
          <NextButton />
        </FooterForm>
      </Form>
    </div>
  );
}
