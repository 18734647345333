import { api } from 'services/api/api';

const saveCompanyNew = async (companyData) => {
  const response = api
    .post(`/api/internal/new-companies`, companyData)
    .then((res) => res)
    .catch((error) => {
      throw error;
    });

  return response;
};

const postCreatePartialCompany = (companyData) => {
  const response = api
    .post(`/api/internal/companies/partial/create`, companyData)
    .then((res) => res)
    .catch((error) => error);

  return response;
};

const getPartialRegisterStatus = (organizationNumber) => {
  const response = api
    .get(
      `/api/internal/companies/partial?organization_number=${organizationNumber}`
    )
    .then((res) => res)
    .catch((error) => error);

  return response;
};

const optionsMacroAreas = () => {
  let response = api
    .get(`/api/internal/macro-areas/options`)
    .then((res) => res.data)
    .catch((error) => error);

  return response;
};

const getOptions = () => {
  let response = api
    .options(`/api/internal/companies/staff/registration-options`)
    .then((res) => res.data)
    .catch((error) => error);

  return response;
};

const getPartialRegister = (organizationNumber) => {
  let response = api
    .get(
      `/api/internal/companies/partial?organization_number=${organizationNumber}`
    )
    .then((res) => res.data)
    .catch((error) => error);

  return response;
};

const getRegisterStatus = (organizationNumber) => {
  let response = api
    .get(
      `/api/internal/companies/registry-status?organization_number=${organizationNumber}`
    )
    .then((res) => res.data)
    .catch((error) => error);

  return response;
};

export {
  saveCompanyNew,
  optionsMacroAreas,
  getOptions,
  getPartialRegister,
  getRegisterStatus,
  getPartialRegisterStatus,
};
