// ##############################
// // // Dashboard View styles
// #############################

import {
  tooltip,
  cardTitle,
  defaultButton,
  rightArrow,
  dnaGreen,
  dnaLightGreen,
  description,
  grayColor,
  dnaColor,
  radioDna,
  yellowIE,
  redIE,
  greenIE,
  darkBlueIE,
  blueIE,
  lightBlueIE,
  orangeIE,
} from 'assets/jss/material-dashboard-pro-react';

import professionalExperienceFormStyles from 'assets/jss/material-dashboard-pro-react/views/components/professionalExperienceFormStyles';
import hoverCardStyle from 'assets/jss/material-dashboard-pro-react/hoverCardStyle';

const dashboardStyle = (theme) => {
  const isIphone = !!navigator.userAgent.match(/iPhone/i);
  const formStyles = professionalExperienceFormStyles(theme);
  return {
    ...hoverCardStyle,
    ...formStyles,
    tooltip,
    IphoneLetterMargin: {
      marginTop: isIphone ? 80 : 'none',
    },
    iconAlign: {
      display: 'flex',
      alignItems: 'center',
    },
    professionCard: {
      display: 'flex',
      justifyContent: 'center',
      width: '100%',
    },
    cardMarginBottom: {
      marginBottom: 0,
    },
    cardMarginTop: {
      marginTop: 0,
    },
    nameContainer: {
      display: 'flex',
      flexDirection: 'row',
      marginBottom: 0,
    },
    marginLeftAuto: {
      marginLeft: 'auto !important',
      display: 'block !important',
      padding: 0,
    },
    dateMargin: {
      margin: '8px 0px 4px 14px',
      fontSize: 12,
      opacity: '75%',
    },
    registrationNumber: {
      fontWeight: 200,
      fontSize: 12,
      margin: '0px 0px',
    },
    registrationNumberMargin: {
      marginBottom: 8,
    },
    jobListApplicationIcons: {
      transform: 'translateY(20%)',
      fontWeight: 200,
      fontSize: 15,
      marginRight: '8px',
    },
    talentsIcon: {
      transform: 'translateY(30%)',
      fontSize: '1.5rem',
      marginRight: '0.250rem',
      fill: '#666',
    },
    finishedStatus: {
      backgroundColor: '#C5C5C5',
    },
    displayNone: {
      [theme.breakpoints.up('sm')]: {
        display: 'none',
      },
    },
    gridStyle: {
      flexGrow: 1,
    },
    cardTitle: {
      ...cardTitle,
      fontSize: 20,
      fontWeight: 400,
      marginTop: 20,
      paddingRight: 30,
      [theme.breakpoints.down('sm')]: {
        marginTop: 15,
      },
    },
    cardSubtitle: {
      ...cardTitle,
      fontWeight: 400,
      fontSize: '1.2em',
      color: '#C5C5C5',
      marginTop: 0,
    },
    cardTitleProfile: {
      ...cardTitle,
      color: 'lastCandidatesListTitle',
    },
    cardEmptyProfile: {
      ...cardTitle,
      textAlign: 'center',
      color: '#999999',
    },
    cardIconTitle: {
      ...cardTitle,
      marginTop: '15px',
      marginBottom: '0px',
    },
    cardProductTitle: {
      ...cardTitle,
      textAlign: 'center',
    },
    cardCategory: {
      color: '#000',
      fontSize: '18px',
      paddingTop: '10px',
      margin: '0',
      lineHeight: '1.7em',
    },
    cardIcon: {
      display: 'flex',
      width: 60,
      height: 60,
      alignItems: 'center',
      marginTop: '-20px',
      '& img': {
        verticalAlign: 'top',
        marginTop: 8,
      },
      [theme.breakpoints.down('sm')]: {
        width: 50,
        height: 50,
      },
    },
    talentsGrid: {
      '& h5': {
        fontSize: '1rem',
        fontWeight: 500,
        color: '#666',
      },
      '& p': {
        fontSize: '1rem',
        fontWeight: 400,
      },
      '& p:first-letter': {
        textTransform: 'capitalize',
      },
      '& span': {
        fontSize: '1rem',
      },
    },
    icon: {
      margin: '0 auto',
      width: 35,
      height: 35,
      '& img': {
        width: '70%',
        height: '70%',
      },
    },
    svgIcons: {
      margin: 0,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      '& svg': {
        margin: '0 !important',
      },
    },
    stats: {
      color: '#999999',
      lineHeight: '22px',
      display: 'inline-flex',
      '& svg': {
        position: 'relative',
        top: '4px',
        width: '16px',
        height: '16px',
        marginRight: '3px',
      },
      '& .fab,& .fas,& .far,& .fal,& .material-icons': {
        position: 'relative',
        top: '4px',
        fontSize: '16px',
        marginRight: '3px',
      },
    },
    defaultButton: {
      ...defaultButton,
    },
    profile: {
      marginTop: 25,
    },
    contentMarginTop: {
      marginTop: 25,
    },
    welcomeMessage: {
      backgroundColor: 'transparent',
      marginBottom: '2em',
      padding: '0 15% 15px',
    },
    closeWelcomeMessage: {
      float: 'right',
    },
    profileTableHeadFontSizeExtracourse: {
      fontSize: '17px !important',
      textAlign: 'left',
      '&:first-child': {
        textAlign: 'center',
      },
      [theme.breakpoints.down('md')]: {
        '&:nth-child(2)': {
          textAlign: 'left',
        },
      },
    },
    profileTableHeadFontSize: {
      fontSize: '17px !important',
      textAlign: 'right',
      '&:first-child': {
        textAlign: 'left',
      },
    },
    profileCustomCellExtracourse: {
      '& td': {
        textAlign: 'left',
      },
      '& td:first-child': {
        textAlign: 'center',
      },
      '&:first-child td': {
        borderTop: 'none',
      },
      [theme.breakpoints.down('md')]: {
        '& td:nth-child(2)': {
          textAlign: 'left',
        },
      },
    },
    profileCustomCell: {
      '& td': {
        textAlign: 'right',
      },
      '& td:first-child': {
        textAlign: 'left',
      },
      '&:first-child td': {
        borderTop: 'none',
      },
      [theme.breakpoints.down('md')]: {
        '& td:nth-child(2)': {
          textAlign: 'left',
        },
      },
    },
    buttonFluid: {
      backgroundColor: dnaColor,
      borderRadius: '2em',
      color: '#FFF',
      justifyContent: 'space-between',
      width: '100%',
      '&:hover': {
        backgroundColor: '#751b7d',
      },
    },

    dnaColorButton: {
      backgroundColor: dnaGreen,
      boxShadow: '-1px 4px 5px 0px #979797',
      '&:hover,&:focus': {
        backgroundColor: dnaGreen,
        boxShadow: '-1px 4px 5px 0px #979797',
      },
    },

    yellowIESButton: {
      backgroundColor: yellowIE,
      boxShadow: '-1px 4px 5px 0px #979797',
      '&:hover,&:focus': {
        backgroundColor: yellowIE,
        boxShadow: '-1px 4px 5px 0px #979797',
      },
    },

    redIESButton: {
      backgroundColor: redIE,
      boxShadow: '-1px 4px 5px 0px #979797',
      '&:hover,&:focus': {
        backgroundColor: redIE,
        boxShadow: '-1px 4px 5px 0px #979797',
      },
    },

    greenIESButton: {
      backgroundColor: greenIE,
      boxShadow: '-1px 4px 5px 0px #979797',
      '&:hover,&:focus': {
        backgroundColor: greenIE,
        boxShadow: '-1px 4px 5px 0px #979797',
      },
    },

    darkBlueIESButton: {
      backgroundColor: darkBlueIE,
      boxShadow: '-1px 4px 5px 0px #979797',
      '&:hover,&:focus': {
        backgroundColor: darkBlueIE,
        boxShadow: '-1px 4px 5px 0px #979797',
      },
    },

    blueIESButton: {
      backgroundColor: blueIE,
      boxShadow: '-1px 4px 5px 0px #979797',
      '&:hover,&:focus': {
        backgroundColor: blueIE,
        boxShadow: '-1px 4px 5px 0px #979797',
      },
    },

    lightBlueIESButton: {
      backgroundColor: lightBlueIE,
      boxShadow: '-1px 4px 5px 0px #979797',
      '&:hover,&:focus': {
        backgroundColor: lightBlueIE,
        boxShadow: '-1px 4px 5px 0px #979797',
      },
    },

    orangeIESButton: {
      backgroundColor: orangeIE,
      boxShadow: '-1px 4px 5px 0px #979797',
      '&:hover,&:focus': {
        backgroundColor: orangeIE,
        boxShadow: '-1px 4px 5px 0px #979797',
      },
    },

    doneStatus: {
      marginRight: 24,
      fontWeight: 500,
      color: '#a2a2a2',
    },
    popUpButtonYes: {
      color: '#fff',
      background: '#F9A400',
    },
    popUpButtonNot: {
      color: '#F9A400',
      background: '#eeeeee',
      '&:hover': {
        background: '#e2e0e0',
        color: '#F9A400',
      },
    },

    dnaColorPopUpButtonYes: {
      color: '#fff',
      background: dnaGreen,
      borderRadius: '8px !important',
    },

    yellowIESPopUpButtonYes: {
      color: '#fff',
      background: yellowIE,
      borderRadius: '8px !important',
    },

    redIESPopUpButtonYes: {
      color: '#fff',
      background: redIE,
      borderRadius: '8px !important',
    },

    greenIESPopUpButtonYes: {
      color: '#fff',
      background: greenIE,
      borderRadius: '8px !important',
    },

    darkBlueIESPopUpButtonYes: {
      color: '#fff',
      background: darkBlueIE,
      borderRadius: '8px !important',
    },

    blueIESPopUpButtonYes: {
      color: '#fff',
      background: blueIE,
      borderRadius: '8px !important',
    },

    lightBlueIESPopUpButtonYes: {
      color: '#fff',
      background: lightBlueIE,
      borderRadius: '8px !important',
    },

    orangeIESPopUpButtonYes: {
      color: '#fff',
      background: orangeIE,
      borderRadius: '8px !important',
    },

    dnaColorPopUpButtonNot: {
      color: dnaGreen,
      border: `1px solid ${dnaGreen} !important`,
      borderRadius: '8px !important',
    },

    yellowIESPopUpButtonNot: {
      color: yellowIE,
      border: `1px solid ${yellowIE} !important`,
      borderRadius: '8px !important',
    },

    redIESPopUpButtonNot: {
      color: redIE,
      border: `1px solid ${redIE} !important`,
      borderRadius: '8px !important',
    },

    greenIESPopUpButtonNot: {
      color: greenIE,
      border: `1px solid ${greenIE} !important`,
      borderRadius: '8px !important',
    },

    darkBlueIESPopUpButtonNot: {
      color: darkBlueIE,
      border: `1px solid ${darkBlueIE} !important`,
      borderRadius: '8px !important',
    },

    blueIESPopUpButtonNot: {
      color: blueIE,
      border: `1px solid ${blueIE} !important`,
      borderRadius: '8px !important',
    },

    orangeIESPopUpButtonNot: {
      color: orangeIE,
      border: `1px solid ${blueIE} !important`,
      borderRadius: '8px !important',
    },

    lightBlueIESPopUpButtonNot: {
      color: lightBlueIE,
      border: `1px solid ${lightBlueIE} !important`,
      borderRadius: '8px !important',
    },

    attractionsGridButton: {
      display: 'flex',
      '& div': {
        width: 'fit-content',
      },
      '& button': {
        padding: '0.5rem 1.5rem',
        margin: 0,
      },
    },
    buttonHelpIcon: {
      float: 'right',
      right: -10,
      position: 'absolute',
    },
    rowColorCompanyProfile: {
      display: 'flex',
      justifyContent: 'start',
      gap: '0.6rem',
    },
    squareCompanyColor: {
      height: '30px',
      width: '30px',
      borderRadius: '4px',
      marginLeft: '9px',
    },
    emptyStateBox: {
      fontSize: 17,
      textAlign: 'center',
      lineHeight: '25px',
      color: '#949494',
      marginTop: 27,
    },
    marginReset: {
      margin: 0,
    },
    marginTopReset: {
      marginTop: 0,
    },
    rightArrow,
    chipTestAnswer: {
      borderRadius: 25,
      fontSize: 17,
      paddingTop: 10,
      paddingRight: 5,
      paddingLeft: 5,
      paddingBottom: 10,
      marginRight: 8,
      marginBottom: 8,
      width: 'auto',
      height: 'auto',
      '& span': {
        whiteSpace: 'normal',
      },
      '@media only screen and (max-width: 460px)': {
        fontSize: 14,
        paddingTop: 10,
        paddingBottom: 10,
        marginRight: 12,
        marginBottom: 12,
      },
    },
    dialogTitle: {
      display: 'block',
      textAlign: 'center',
      marginLeft: 0,
      paddingRight: 162,
      width: '100%',
    },
    '@media (max-width: 959.95px)': {
      dialogTitle: {
        paddingRight: 0,
      },
      contentMarginTop: {
        marginTop: 55,
      },
    },
    areaGap: {
      display: 'grid',
      gridTemplateColumns: '33% 33% 33%',
      gridGap: '20px',
      marginRight: '25px',
      marginTop: '40px',

      [theme.breakpoints.down('md')]: {
        gridTemplateColumns: '50% 50%',
      },
      [theme.breakpoints.down('xs')]: {
        gridTemplateColumns: '100%',
        marginRight: '20px',
      },
    },
    areas: {
      boxShadow: `rgba(174,220,220,255) 0px 1px 4px, rgba(174,220,220,255) 0px 0px 4px 5px`,
      border: `2px solid rgba(232,232,232,255)`,
      borderRadius: 15,
      padding: 15,
      flexWrap: 'nowrap',
      justifyContent: 'space-between',
    },
    seeJobOpportunity: {
      display: 'flex',
      justifyContent: 'center',
      paddingTop: 15,
      alignItems: 'flex-end',
      '& button': {
        width: '95%',
        height: 0,
        float: 'inherit',
        '& span': {
          height: 0,
        },
      },
    },
    average: {
      color: dnaGreen,
      display: 'flex',
      fontSize: 45,
      justifyContent: 'center',
      marginRight: 10,
      [theme.breakpoints.down('md')]: {
        fontSize: 25,
        margin: 0,
      },
    },
    averageContainer: {
      display: 'flex',
      alignItems: 'center',
      [theme.breakpoints.down('md')]: {
        flexDirection: 'column',
        textAlign: 'center',
      },
      [theme.breakpoints.down('sm')]: {
        display: 'flex',
        justifyContent: 'center',
        width: '100%',
        flexDirection: 'column',
        textAlign: 'center',
      },
    },
    averageContainerMyCareer: {
      display: 'flex',
      alignItems: 'center',

      [theme.breakpoints.down('sm')]: {
        justifyContent: 'center',
        width: '100%',
        textAlign: 'center',
      },
    },
    profession: {
      fontSize: '1.375em',
      fontWeight: '400',
    },
    icoInfo: {
      padding: 0,
      marginRight: 10,
      [theme.breakpoints.down('md')]: {
        marginRight: 0,
      },
    },
    icons: {
      alignItems: 'start',
      justifyContent: 'flex-end',
      display: 'flex',
      [theme.breakpoints.down('md')]: {
        justifyContent: 'center',
      },
    },
    myProfileContentMargin: {
      marginTop: 270,
    },
    myCareerContentMargin: {
      marginTop: 110,
      [theme.breakpoints.up('md')]: {
        marginTop: 240,
      },
    },
    contentExpansionPanel: {
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    expansionDetailPanel: {
      display: 'block',
      paddingBottom: 0,
      paddingTop: 0,
    },
    cnpj: {
      fontWeight: 500,
      marginBottom: '20px',
    },
    announceDate: {
      fontWeight: 500,
      fontSize: 18,
      color: '#9e9e9e',
      marginBottom: 5,
    },
    heading: {
      fontSize: 17,
      fontWeight: 400,
    },
    headingInvite: {
      fontSize: 17,
    },
    buttonStatusInformation: {
      borderColor: '#009291',
      color: '#009291',
      background: 'none',
      border: '2px solid #009291',
      textAlign: 'center',
      borderRadius: '8.9px',
      minWidth: '139.65px',
      '&:hover': {
        border: '1px solid dnaGreen',
        background: dnaGreen,
        color: '#fff',
      },
      '&:disabled': {
        color: '#949494',
        border: '2px solid #949494',
        opacity: '0.65',
      },
    },
    secondaryHeading: {
      display: 'flex',
      justifyContent: 'center',
      flexDirection: 'column',
      width: '100%',
      minWidth: 130,
      maxWidth: 190,
      fontSize: 17,
      '& p:first-child': {
        marginBottom: 5,
        fontSize: 17,
      },
      '& p:last-child': {
        marginBottom: 0,
      },
      maxWidth: 190,
      textAlign: 'center',
    },
    fontSizeParagraphDate: {
      fontSize: '12px',
      minWidth: '80px',
      fontWeight: 400,
      color: 'gray',
      margin: '0 0 30px',
    },
    secondaryHeadingRejected: {
      width: '100%',
      color: '#ff0000',
      maxWidth: 190,
      fontSize: 17,
      '& p:last-child': {
        marginBottom: 0,
      },
      textAlign: 'center',
    },
    secondaryHeadingDisabled: {
      width: '100%',
      color: '#99999',
      maxWidth: 190,
      fontSize: 17,
      '& p:last-child': {
        marginBottom: 0,
      },
      textAlign: 'center',
    },
    headingTitle: {
      fontSize: 17,
      color: '#999',
    },
    candidatesCount: {
      color: grayColor,
    },
    expandedItem: {
      borderRadius: '10px !important',
      backgroundColor: '#f7f7f7',
      padding: 10,
      '& $heading': {
        fontSize: 35,
        lineHeight: '1em',
      },
      '& $candidatesCount': {
        display: 'none',
      },
      '& $contentExpansionPanel': {
        marginBottom: '0 !important',
      },
    },
    expandedItemFinished: {
      borderRadius: '10px !important',
      backgroundColor: '#EFEEEC',
      padding: 5,
      '& $heading': {
        fontSize: 35,
        lineHeight: '1em',
      },
      '& $candidatesCount': {
        display: 'none',
      },
      '& $contentExpansionPanel': {
        marginBottom: '0 !important',
      },
    },
    grid: {
      padding: '0!important',
    },
    professionDetailButton: {
      display: 'contents',
      fontSize: 20,
      fontWeight: 400,
      margin: 0,
      padding: 0,
      textAlign: 'left',
      textTransform: 'inherit',
      '& span': {
        lineHeight: '1.2em',
      },
    },
    requirementsDescription: {
      textAlign: 'justify',
      padding: 15,
    },
    progressArea: {},
    progress: {},
    dialogFullScreenTitle: {},
    green: {
      '& $cardIcon': {
        backgroundColor: '#1cb45e',
        borderRadius: '5px',
        marginRight: '1em',
        '& img': {
          width: 'auto',
        },
        '& $dialogFullScreenTitle': {
          color: '#1cb45e',
        },
      },
      '& $buttonFluid': {
        backgroundColor: '#1cb45e',
      },
      '& $progressArea': {
        color: '#1cb45e',
      },
      '& $progress': {
        color: '#1cb45e',
      },
    },
    purple: {
      '& $cardIcon': {
        backgroundColor: '#692a71',
        borderRadius: '5px',
        marginRight: '1em',
      },
      '& $buttonFluid': {
        backgroundColor: '#692a71',
      },
      '& $progressArea': {
        color: '#692a71',
      },
    },
    blue: {
      '& $cardIcon': {
        backgroundColor: '#0183ad',
        borderRadius: '5px',
        marginRight: '1em',
      },
      '& $buttonFluid': {
        backgroundColor: '#0183ad',
      },
      '& $progressArea': {
        color: '#0183ad',
      },
    },
    yellow: {
      '& $cardIcon': {
        backgroundColor: '#eb9b00',
        borderRadius: '5px',
        marginRight: '1em',
      },
      '& $buttonFluid': {
        backgroundColor: '#eb9b00',
      },
      '& $progressArea': {
        color: '#eb9b00',
      },
    },
    companyPreRegiterButton: {
      ...defaultButton,
      marginTop: -70,
      float: 'none',
    },
    editButton: {
      float: 'right',
      bottom: 15,
      marginTop: -17,
      marginLeft: 15,
      '& svg': {
        width: 31,
        height: 32,
        marginLeft: -10,
      },
    },
    editButtonReceived: {
      border: 'none',
      padding: '0',
      fontSize: '16px',
      cursor: 'pointer',
      color: '#4BAFAD',
      textDecoration: 'underline',
      display: 'flex',

      width: '42px',
      height: '42px',

      float: 'right',
      marginLeft: 15,

      '& svg': {
        width: 31,
        height: 32,
        marginLeft: -10,
      },
    },
    cancelButton: {
      marginTop: -10,
      color: '#990000',
    },
    switchColor: {
      color: `${dnaGreen}!important`,
    },
    track: {
      backgroundColor: `${dnaGreen}!important`,
    },
    finishVacancy: {
      fontWeight: 'bold',
      marginRight: 40,
      letterSpacing: '.08em',
      fontSize: '10px',
      textAlign: 'center',
      width: '130px',
      height: '30px',
    },
    jobsDetailButton: {
      color: dnaGreen,
      fontSize: '1rem',
      fontWeight: 500,
      padding: 0,
    },
    appliedJobCardCancelButton: {
      width: 'fit-content',
      color: 'red',
      fontSize: 12,
      fontWeight: 300,
      textDecoration: 'underline',
    },
    appliedJobCardCancel: {
      display: 'flex',
      justifyContent: 'flex-end',
      padding: '0 1rem',
    },
    contractedButton: {
      color: '#009291',
      border: '2px solid #009291',
      borderRadius: '100px',
      fontWeight: 500,
      fontSize: '1rem',
    },
    iconGreen: {
      color: dnaGreen,
    },
    iconAction: {
      color: grayColor,
    },
    iconLetter: {
      color: dnaColor,
    },
    jobPositionActions: {
      display: 'flex',
      flexDirection: 'row',
      paddingTop: 15,
      justifyContent: 'space-between',
    },
    lastCandidates: {
      width: '100%',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    flex: {
      display: 'flex',
    },
    flexAlignCenter: {
      display: 'flex',
      flexDirection: 'column',
    },
    internshipInformation: {
      display: 'flex',
      alignItems: 'center',
      padding: '7px 0',
      '& span': {
        fontSize: '11.9px',
        cursor: 'pointer',
        color: '#4BAFAD',
        textDecoration: 'underline',
      },
      '& button': {
        border: 'none',
        background: 'none',
        padding: '0',
      },
    },
    miniInfos: {
      border: '1px sold',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',

      '& span': {
        fontSize: '11.6px',
        color: 'rgba(0, 0, 0, 0.87)',
        textDecoration: 'none',
        padding: '0 3px',
      },

      '& p': {
        margin: '0',
        padding: '0',
      },
    },
    containerInfos: {
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'row',
    },
    candidateName: {
      fontSize: 16,
    },
    ratingSpacing: {
      display: 'flex',
      alignItems: 'center',
    },
    iconYellow: {
      color: 'yellow',
    },
    candidatesListActions: {
      width: 225,
      display: 'flex',
      justifyContent: 'space-between',
    },
    lastCandidatesListTitle: {
      fontSize: 18,
    },
    talentsListSubheader: {
      paddingRight: 0,
      paddingLeft: 0,
      backgroundColor: '#fff',
    },
    talentsListAction: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
    },
    talentsButtonConnect: {
      padding: '5px 0',
      fontSize: '1rem',
    },
    talentsFilterButton: {
      float: 'right',
      fontSize: 11,
    },
    buttonSpace: {
      display: 'flex',
      justifyContent: 'center',
      height: 42,
      alignItems: isIphone ? 'center' : 'none',
      marginBottom: isIphone ? '1em' : 'none',
    },
    letterButton: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      minHeight: 46,
      padding: 0,
      margin: 0,
    },
    flexCenter: {
      display: 'flex',
      height: isIphone ? 46 : 42,
      marginBottom: '2.5em',
    },
    flexCenterLoading: {
      display: 'flex',
      justifyContent: 'center',
      marginBottom: '2.5em',
    },
    buttonGroup: {
      flexDirection: 'column',
      alignItems: 'center',
    },
    description: {
      ...description,
      [theme.breakpoints.down('xs')]: {
        fontSize: 14,
      },
    },
    profileDescription: {
      ...description,
      textTransform: 'capitalize',
    },
    addButton: {
      padding: '8px, 20px',
      float: 'right',
      marginBottom: '30px',
    },
    jobRegistrationButton: {
      backgroundColor: '#fff',
      color: dnaGreen,
      marginTop: 105,
      marginRight: '2.5%',
      zIndex: 2,
    },
    infoIcon: {
      position: 'absolute',
      right: -28,
      top: -15,
    },
    inviteInfoContainer: {
      display: 'flex',
      width: '100%',
      '& svg': {
        fill: '#666',
        marginRight: '15px',
      },
      '& span': {
        display: 'inline-block',
        width: '100%',
      },
    },
    infoInternship: {
      display: 'flex',
      flexDirection: 'column',
      paddingTop: '10px',
    },
    boxImageSpan: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    boxLabel: {
      display: 'flex',
      alignItems: 'center',
      width: '100%',
      maxWidth: '400px',
    },
    buttonDownload: {
      border: 'none',
      paddingLeft: '20px',
      margin: '0',

      fontSize: '14px',
      fontWeight: '500',

      color: '#4a4a49',
      backgroundColor: 'transparent',
      textDecorationLine: 'underline',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      cursor: 'pointer',
    },
    wrapperInfos: {
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',

      '& svg': {
        fill: '#666',
        margin: '0',
      },

      '& p': {
        paddingLeft: '20px',
        margin: '0',
        textDecorationLine: 'underline',
        width: '100%',
      },
    },

    containerButtons: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',

      '& button': {
        border: 'none',
        background: 'none',

        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',

        width: '24px',
        cursor: 'pointer',

        marginLeft: '10px',
      },
    },
    openModalStyles: {
      border: 'none',
      padding: '0',
      background: 'none',
      fontSize: '16px',
      cursor: 'pointer',
      color: '#4BAFAD',
      textDecoration: 'underline',
      display: 'flex',

      width: '100%',
    },
    spanSelection: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      '& p': {
        padding: '0 5px 0 55px',
      },
      '& span': {
        padding: '0 2px',
      },
    },
    convenioInfo: {
      display: 'flex',
    },
    divider: {
      margin: '20px 0',
    },
    collapsed: {
      'white-space': 'normal',
      display: '-webkit-box',
      WebkitBoxOrient: 'vertical',
      WebkitLineClamp: 4,
    },
    greenTextButton: {
      color: dnaGreen,
    },
    expandTextButton: {
      float: 'right',
      transformOrigin: 'center center',
      transition: 'transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    },
    expanded: {
      transform: 'rotate(180deg)',
      transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
      }),
    },
    expand: {
      transform: 'rotate(0deg)',
      transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
      }),
    },
    cardHeader: {
      '& h3': {
        margin: '12px 0 12px 0',
      },
    },
    jobCard: {
      '& h3': {
        padding: 0,
      },
      [theme.breakpoints.down('sm')]: {
        textAlign: 'center',
      },
    },
    publishedJobCardBody: {
      width: '100%',
      justifyContent: 'space-between',
      [theme.breakpoints.down('md')]: {
        '& div': {
          marginBottom: 20,
          width: '100%',
        },
      },
    },
    jobOfferDetailButton: {
      marginRight: 20,
      [theme.breakpoints.down('xs')]: {
        marginRight: 0,
        marginTop: 10,
      },
    },
    jobCardHeader: {
      display: 'flex',
      flexDirection: 'column',
      '& h3': {
        marginTop: 0,
      },
    },
    helpFooter: {
      display: 'inline-block',
      width: 'auto',
      textAlign: 'center',
      position: 'relative',
      marginLeft: '28%',
      marginTop: '4rem',
    },
    jobCardBody: {
      display: 'flex',
      justifyContent: 'space-between',
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
        '& div': {
          marginBottom: 20,
        },
      },
    },
    jobCardFooter: {
      display: 'flex',
      alignSelf: 'flex-end',
      marginBottom: 12,
      [theme.breakpoints.down('md')]: {
        alignSelf: 'center',
      },
      [theme.breakpoints.down('xs')]: {
        flexDirection: 'column-reverse',
      },
    },
    unpublishedJobCardTitle: {
      color: '#a9a9a9',
      display: 'flex',
      flexDirection: 'column',
    },
    unpublishedJobCardBody: {
      width: '50%',
      justifyContent: 'space-between',
      [theme.breakpoints.down('md')]: {
        width: '100%',
        '& div': {
          marginBottom: 20,
        },
      },
    },
    applied: {
      display: 'flex',
      alignItems: 'center',
      color: '#4caf50',
    },
    imageFluid: {
      width: '100%',
    },
    gridItemVideo: {
      marginBottom: '2em',
    },
    containerThumbHolder: {
      display: 'flex',
      justifyContent: 'start',
      flexWrap: 'wrap',
      gap: '10px',

      [theme.breakpoints.down('lg')]: {
        justifyContent: 'center',
      }
    },
    videoThumbHolder: {
      margin: 0,
      '& img': {
        marginBottom: '.5em',
      },
      '& figcaption': {
        fontSize: '1.2em',
        padding: '.5em 0',
      },
    },
    videoTrigger: {
      border: 0,
      cursor: 'pointer',
      margin: 0,
      padding: 0,
      transform: 'scale(1)',
      transition: 'transform .2s ease-in',
      '&:hover': {
        transform: 'scale(1.03)',
      },
    },
    availableCard: {
      flexDirection: 'row',
      [theme.breakpoints.down('md')]: {
        flexDirection: 'column',
      },
    },
    avaiableCardBody: {
      width: '100%',
      [theme.breakpoints.down('md')]: {
        width: '100%',
        justifyContent: 'space-between',
      },
    },
    applicationAnswer: {
      width: '50%',
      padding: '15px 20px',
      borderRadius: '0 10px 10px 0',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      textAlign: 'center',
      borderLeft: '1px solid #EFEEEC',
      '& h3': {
        margin: 0,
        marginBottom: 10,
      },
      '& p': {
        margin: 0,
      },
      [theme.breakpoints.down('md')]: {
        width: '100%',
        borderLeft: 'none',
        borderTop: '1px solid #EFEEEC',
        borderRadius: '0 0 10px 10px',
      },
    },
    successReturn: {
      backgroundColor: '#1cb45e',
      color: '#FFF',
    },
    inviteInterview: {
      backgroundColor: '#2d9cdb',
      color: '#FFF',
    },
    canceledInterview: {
      backgroundColor: '#eb5757',
      color: '#FFF',
    },
    unpublishedJob: {
      backgroundColor: '#c5c5c5',
      '& h3': {
        margin: '0 0 10px',
      },
    },
    availableCardBody: {
      display: 'flex',
      flexDirection: 'column',
    },
    avaiableCardFooter: {
      margin: 0,
      display: 'grid',
      gridTemplateRows: 'auto auto',
      gridTemplateColumns: '100%',
      padding: '0',
    },
    availableCardDiv: {
      display: 'flex',
      justifyContent: 'space-between',
      width: '100%',
      fontSize: '1.2rem',
      fontWeight: 400,
    },
    capitalizeText: {
      textTransform: 'capitalize',
      marginTop: '0.5rem',
      fontSize: '1rem',
      fontWeight: 500,
    },
    emptyText: {
      fontSize: 17,
      textAlign: 'center',
      lineHeight: '25px',
      margin: 20,
    },
    candidatesContainer: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
    },
    loading: {
      color: '#009291',
      display: 'block',
      margin: '40px auto',
    },

    dnaColorLoading: {
      color: '#009291',
      display: 'block',
      margin: '40px auto',
    },

    redIESLoading: {
      color: `${redIE}`,
      display: 'block',
      margin: '40px auto',
    },

    yellowIESLoading: {
      color: `${yellowIE}`,
      display: 'block',
      margin: '40px auto',
    },

    greenIESLoading: {
      color: `${greenIE}`,
      display: 'block',
      margin: '40px auto',
    },

    blueIESLoading: {
      color: `${blueIE}`,
      display: 'block',
      margin: '40px auto',
    },

    darkBlueLoading: {
      color: `${darkBlueIE}`,
      display: 'block',
      margin: '40px auto',
    },

    lightBlueLoading: {
      color: `${lightBlueIE}`,
      display: 'block',
      margin: '40px auto',
    },

    orangeLoading: {
      color: `${orangeIE}`,
      display: 'block',
      margin: '40px auto',
    },

    iconSucces: {
      color: '#4caf50',
    },
    iconRed: {
      color: '#f44336',
    },
    requirementsText: {
      display: 'flex',
      marginTop: '-30px',
      textAlign: 'center',
      '& div': {
        marginRight: 5,
      },
    },
    doneIcons: {
      marginRight: 30,
    },
    doneAll: {
      fill: dnaGreen,
    },
    smallInfoText: {
      textAlign: 'center',
      [theme.breakpoints.down('xs')]: {
        marginBottom: 70,
      },
    },
    disabledText: {
      color: '#ff0000',
      textAlign: 'right',
      marginBottom: 0,
      fontSize: '13px',
    },
    selectLabel: {
      whiteSpace: 'nowrap',
    },
    selectFormControl: {
      width: 86,
      marginRight: 20,
    },
    selectInput: {
      marginTop: '10px !important',
      textAlign: 'center',
      fontWeight: 'normal',
      '&:after': {
        borderBottomColor: dnaColor,
      },
    },
    select: {
      width: '100%',
      '& > div': {
        backgroundColor: 'rgb(245, 245, 245)',
      },
      [theme.breakpoints.down('sm')]: {
        width: '100%',
      },
      margin: '0 auto',
      fontSize: 17,
      '& .react-select__menu': {
        zIndex: 999,
      },
      '& > div:nth-child(1)': {
        minHeight: 56,
        border: 0,
        borderRadius: 10,
        '& > div': {
          marginLeft: 4,
          marginTop: 10,
        },
      },
      '& > div > div:nth-child(2)': {
        display: 'none',
      },
    },
    selectControlHeight: {
      '& > div:nth-child(1)': {
        paddingTop: 10,
        height: 'auto',
      },
      '& > div:nth-child(2)': {
        paddingTop: 10,
        height: 'auto',
      },
    },
    cssLabel: {
      '&$cssFocused': {
        color: dnaColor,
      },
    },
    cssFocused: {},
    marginTop53: {
      [theme.breakpoints.down('xs')]: {
        marginTop: 53,
      },
    },
    finishVacancyBtn: {
      padding: '2px 37px',
      marginRight: '32px',
      borderColor: '#009291',
      color: '#009291',
      width: '142px',
      borderRadius: '200px',
    },
    lightTooltip: {
      backgroundColor: theme.palette.common.white,
      color: 'rgba(0, 0, 0, 0.87)',
      boxShadow: theme.shadows[1],
      fontSize: 12,
      width: 180,
    },
    noBoxShadow: {
      boxShadow: 'none',
    },
    certButton: {
      color: dnaLightGreen,
      fontSize: 20,
      width: 'fit-content',
      alignSelf: 'center',
      letterSpacing: 1,
      '& svg': {
        marginRight: 10,
        color: dnaLightGreen,
        fontSize: 36,
      },
    },
    disabled: {
      color: 'rgba(0, 0, 0, 0.26) !important',
      '& svg': {
        color: 'rgba(0, 0, 0, 0.26) !important',
      },
    },
    extracourseTableButton: {
      border: 'none',
      color: 'darkcyan',
      backgroundColor: 'transparent',
      cursor: 'pointer',
    },
    chipOptions: {
      background: 'whitesmoke',
      width: '100%',
      borderRadius: '9px',
      padding: '10px 8px',
    },

    boxModalCompany: {
      position: 'fixed',
      top: 0,
      backgroundColor: '#eeeeee',
      zIndex: 1300,
      paddingLeft: '4rem',
      paddingRight: '4rem',
      paddingTop: '2rem',
      width: 'calc(100% - 259px)',
    },

    optionsLabel: {
      paddingLeft: '8px',
      marginTop: '-3px !important',
      marginBottom: '2px',
      '& label': {
        color: 'rgba(0, 0, 0, 0.54) !important',
        padding: 0,
        fontSize: '12px !important',
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif !important',
      },
    },
    companyFilterInvites: {
      paddingLeft: 15,
      paddingBottom: 55,
      position: 'relative',
      zIndex: 10,
    },
    hideFilterInvites: {
      display: 'none !important',
    },
    transparentButton: {
      color: '#009291',
      backgroundColor: 'transparent',
      border: '1px solid #009291',
      marginRight: 30,
      transition: '0.5s ease-in',
      '&:hover': {
        color: '#FFF',
        backgroundColor: '#009291',
      },
    },
    smallIcon: {
      color: '#636363',
      fontSize: 14,
      marginRight: '4px',
      marginTop: '-1px',
    },
    mediumIcon: {
      color: '#999999',
      fontSize: 20,
      margin: '3px 6px -3px 0px',
    },
    columnAlignCenter: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    resendeButton: {
      fontSize: 12,
      marginRight: 32,
    },
    profileContainer: {
      marginTop: 120,
      [theme.breakpoints.down('sm')]: {
        marginTop: 80,
      },
    },
    btnConfirmVacany: {
      width: '100%',
      color: '#009291',
      border: '2px solid #009291',
      borderRadius: '200px',
      fontSize: '0.875rem',
    },
    responsibleJob: {
      fontSize: 13,
      '& span': {
        color: '#309190',
        fontWeight: 400,
      },
    },

    titleCard: {
      fontSize: 20,
      color: dnaColor,
      fontWeight: 400,
    },

    titleDefault: {
      textAlign: 'center',
      marginBottom: 30,
      fontSize: '1.2rem',
    },

    starIconLabel: {
      position: 'relative',
      top: 5,
      color: '#c5c5c5',
    },

    tooltipEvent: {
      background: '#e4e4e4 !important',
      color: '#6f6f6f !important',
      fontSize: 12,
      lineHeight: '8px',
      padding: '11px 11px 6px',
      textAlign: 'center',
    },

    helpPopoverIcon: {
      color: dnaGreen,
      width: 28,
      height: 28,
    },

    popoverCloseIcon: {
      color: '#000000',
      width: 16,
      height: 16,
    },

    popoverContent: {
      display: 'flex',
      flexDirection: 'row',
    },

    companyInstitution: {
      display: 'flex',
      justifyContent: 'space-between',
      borderBottom: '1px solid #c9c9c9',
      marginBottom: '10px',
    },

    itemInstitution: {
      display: 'flex',
      flexDirection: 'column',
      margin: '0px 0px 20px 10px',
    },
    itemInstitutionDetails: {
      display: 'inline-block',
      margin: '5px 20px 0px 33px',
      color: '#999999',
    },
    itemInstitutionName: {
      display: 'inline-block',
      margin: '3px 10px 0px 10px',
      fontWeight: 400,
      fontSize: 17,
    },

    popoverIconSection: {
      paddingLeft: '2px',
    },
    displayNone4now: {
      display: 'none',
    },

    certificateMessage: {
      textAlign: 'center',
      color: '#b0b0b0',
      fontSize: '16px',
      fontWeight: 400,
    },

    attractionsContainer: {
      display: 'flex',
      flexWrap: 'wrap',
      marginTop: '23px',
      justifyContent: 'center',
    },
    attractionsFilter: {
      display: 'inline-block',
      position: 'relative',
      zIndex: 10,
    },
    analysisApprove: {
      color: '#06b906',
    },
    analysisPending: {
      color: '#909090',
    },
    analysisRefused: {
      color: '#e29303',
    },
    analysisCheckedAndRefused: {
      color: '#F1312E',
    },
    gridTalents: {
      display: 'grid',
      gridTemplateColumns: '100%',
    },
    subtitleTalents: {
      marginLeft: '2.2rem',
      fontSize: '12px',
      color: '#C8C8C8',
    },
    titleTalents: {
      margin: 0,
      fontSize: '1rem',
      color: '#50A2F8',
      fontWeight: 400,
      textDecoration: 'underline',
    },
    buttonNoStyle: {
      backgroundColor: 'transparent',
      border: 'none',
      padding: '0',
      display: 'block',
      cursor: 'pointer',
    },
    extracourseModalListItem: {
      justifyContent: 'space-around',
      marginTop: '1rem',
      marginBottom: '1rem',
    },
    extracourseModalButtons: {
      borderStyle: 'none',
      borderRadius: '1.5rem',
      fontWeight: 600,
      height: 'fit-content',
      padding: '0.5rem 3rem',
      margin: 0,

      fontSize: '1rem',

      transition: 'opacity 0.4s, background 0.2s',

      '&:hover': {
        cursor: 'pointer',
        opacity: '0.8',
      },
    },
    talentsButton: {
      boxShadow: 'none',
      background: 'transparent',
      '&:hover': {
        background: '#9e9e9e',
      },
    },
    pdfButton: {
      cursor: 'pointer',
      border: 'none',
      backgroundColor: 'transparent',
    },
    tableButton: {
      fontSize: '1em',
      fontWeight: 300,
      fontFamily: "'Roboto', 'Helvetica', 'Arial', 'sans-serif'",
    },
    radioInput: {
      ...radioDna,
    },
  };
};

export default dashboardStyle;
