import styled from 'styled-components';
import error from 'assets/Matriz/error.svg';

export const UnInput = styled.input`
  border: ${({error}) =>
    error ? '1px solid #FFCECE' : ' 1px solid #d0d0d0'};
  width: 100%;
  padding: 12px;
  border-radius: 4px;
  background-color: ${({error}) => (error ? '#FFEDED' : '#FFFF')};
  &:focus {
    border: 2px solid ${({universityColor}) => universityColor || '#2784ff'};
  }
  height: ${({height}) => height || '38px'};
  margin-top: 6px;

  &::placeholder {
    color: #949494;
  }
`;

export const ContainerLabelInput = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
  margin-top: 8px;
  ${({ removeMargin }) => removeMargin && `margin: ${'0px'};`}
  position: relative;
`;

export const IconSearchAddress = styled.div`
  position: absolute;
  top: ${(props) => props.marginTop || '58px'};
  right: 10px;
  transform: translateY(-50%);
  pointer-events: none;
`;

export const UnInputLabel = styled.label`
  color: #6d6d6d;
  font-size: ${(props) => props.fontSize || '15px'};
  margin-top: 10px;
  display: ruby;
  align-items: center;
`;

export const Count = styled.span`
  position: absolute;
  bottom: -25px;
  right: 0;
`;

export const Error = styled.span`
  display: flex;
  align-items: center;
  gap: 12px;
  margin: 5px 0px;
  font-size: 12px;

  &:before {
    content: url(${error});
    width: 10px;
    height: 15px;
  }
`;

export const HintInformation = styled.div`
  width: 5px;
  height: 5px;
  border: 1px solid;
  margin-left: 5px;
  padding: 5px;
  border-radius: 50%;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-size: 8px;
`;
