import { useContext, useEffect, useState } from 'react';
import { JobOfferContext } from '../../../JobOfferRebuild/Contexts/JobOfferContext';
import React, { Fragment } from 'react';

import EngineSteps from '../../../JobOfferRebuild/Components/JobOfferForm/Steps/EngineSteps';
import RegisterEngine from './Register';

export default function EngineRegister(props) {
  const { registerSteps } = useContext(JobOfferContext);

  const currentStepComponent = [
    <RegisterEngine
      {...props}
    />,
    <EngineSteps />,
  ];

  return <Fragment>{currentStepComponent[registerSteps]}</Fragment>;
}
