import { create } from 'zustand';

const initialState = {
  state: {
    organizationNumber: null,
    status: 'new'
  },
};

export const LocationStore = create((set) => ({
  locationStore: initialState.state,

  changeLocationStore: (state) =>
    set(() => {
      return { locationStore: state };
    }),
  updateLocationStore: (key, value) =>
    set((state) => {
      const data = { ...state.locationStore, [key]: value };
      return (state.locationStore = data);
    }),
}));

export const actionsLocationStore = (() => {
  const changeLocationStore = LocationStore.getState().changeLocationStore;
  const updateLocationStore = LocationStore.getState().updateLocationStore;

  return { changeLocationStore, updateLocationStore };
})();

export const useLocationStore = () => {
  const locationStore = LocationStore((state) => state.locationStore);
  return { locationStore };
};
