import React, { Fragment, useState, useEffect } from 'react';
import moment from 'moment';
import {
  HeaderContainer,
  HeaderInfos,
  ListContainer,
  ButtonConnect,
  PaginationStyle,
} from '../style';

import academicInnovationApi from 'views/Backoffice/academicInnovation/services/academicInnovationApi';
import DropDownPending from './dropDown';
import AssociateTagForm from '../../Forms/AssociateTagForm';
import { useFormContext } from 'views/Backoffice/academicInnovation/contexts';
import { useDebounce } from 'hooks/useDebounce';
import Pagination from 'components/CustomPagination';
import {
  searchQuery,
  searchPage,
} from 'views/Backoffice/academicInnovation/Screens/Tabs/utils/queryString';
import AssociateTagUniversityForm from '../../Forms/AssociateTagUniversityForm';
import GenericMessage from 'components/GenericMessage';
import { CircularProgress } from '@material-ui/core';

export default function PendingComponent({
  setCountPendingTag,
  countPendingTag,
}) {
  const {
    queryFilter,
    searchTeam,
    pendingTags,
    setPendingTags,
    setCurrentFormToShow,
    limit,
    setLimit,
    isListEmpty,
    setListEmpty,
    isLoading,
    setIsLoading,
    currentPage,
    setCurrentPage,
  } = useFormContext();
  
  const listPendingTags = async (search) => {
    setIsLoading(true);

    const { results, count } = await academicInnovationApi.getPendingTagsList(
      search
    );
    setPendingTags(results);
    setCountPendingTag(count);
    if (results.length === 0) {
      setListEmpty(true);
    } else {
      setListEmpty(false);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    const timeout = setTimeout(() => {
      const search = searchQuery(limit, queryFilter, searchTeam);
      listPendingTags(search);
      setCurrentPage(1);
    }, 600);
    return () => clearTimeout(timeout);
  }, [searchTeam]);

  const formatDate = (date) => {
    return moment(date).format('DD/MM/YYYY');
  };

  const handleClickAssociate = (item) => {
    setCurrentFormToShow(<AssociateTagUniversityForm item={item} />);
  };


  const handlePageChange = async (page) => {
    const newPage = searchPage(page, limit, queryFilter, searchTeam);
    await listPendingTags(newPage);
    setCurrentPage(page);
  };

  const handleLimitChange = async (newLimit) => {
    const query = searchQuery(newLimit, queryFilter, searchTeam);
    setCurrentPage(1);
    await listPendingTags(query);
    setLimit(newLimit);
  };

  return (
    <>
      <Fragment>
        {isLoading ? (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              margin: '50px',
            }}
          >
            <CircularProgress style={{ color: '#009291' }} />
          </div>
        ) : countPendingTag === 0 ? (
          <GenericMessage
            title="Nenhuma TAG Pendente foi encontrada"
            subtitle={
              'Você pode cadastrar novas TAGs no ambiente da instituição de ensino. '
            }
          />
        ) : (
          <div>
            <HeaderContainer>
              <HeaderInfos>
                <span className="header-name-tag">NOME DA TAG</span>
                <span className="header-data"> DATA</span>
                <span className="header-institution"> INSTITUIÇÃO</span>
              </HeaderInfos>
            </HeaderContainer>
            {pendingTags.map((item, index) => (
              <ListContainer key={index}>
                <DropDownPending item={item} />
                <span className="list-data">{formatDate(item.created_at)}</span>
                <span className="list-institution">{item.university}</span>
                <ButtonConnect onClick={() => handleClickAssociate(item)}>
                  Associar
                </ButtonConnect>
              </ListContainer>
            ))}
          </div>
        )}
      </Fragment>
      <PaginationStyle>
        <Pagination
          className="pagination-bar"
          currentPage={currentPage}
          totalCount={countPendingTag}
          onPageChange={handlePageChange}
          setLimit={handleLimitChange}
          showLimit={countPendingTag > 10}
          setCurrentPage={setCurrentPage}
          pageRef={null}
          limit={limit}
        ></Pagination>
      </PaginationStyle>
    </>
  );
}
