import React, { useEffect, useRef, useState } from 'react';
import { useField } from '@unform/core';
import {
  ContainerLabelInput,
  Count,
  Error,
  HintInformation,
  IconSearchAddress,
  UnInput,
  UnInputLabel,
} from './style';
import Tooltip from 'components/NewTooltip';
import { AiOutlineSearch } from 'react-icons/ai';

export default function Input({
  label,
  name,
  showErrorLabel = true,
  maxLength,
  onChange,
  useCount,
  width,
  padding,
  removeMargin,
  useHint,
  hintConfig,
  formatDecimal,
  height,
  fontSize,
  universityColor,
  hasMask = false,
  onSearchIcon,
  mandatoryLabel,
  ...rest
}) {
  const inputRef = useRef(null);
  const { fieldName, registerField, defaultValue, error, clearError } =
    useField(name);
  const [count, setCount] = useState(0);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value',
      setValue: (ref, value) => {
        ref.value = value ? value : '';
        if (useCount) {
          setCount(value.length);
        }
      },
    });
  }, [fieldName, registerField]);

  const formatValue = (value) => {
    if (!formatDecimal) return value;
    let newValue = value;
    if (typeof value === 'number') {
      newValue = String(value);
    }
    const numericValue = Number(
      newValue.replace(/[^0-9,-]+/g, '').replace(',', '.')
    );
    return new Intl.NumberFormat('pt-br').format(numericValue);
  };

  const handleChange = (event, useDefault) => {
    let value = useDefault ? event : event.target.value;
    value = formatValue(value);

    if (onChange) {
      if (formatDecimal) {
        onChange(value);
      } else {
        onChange(event);
      }
    }
    if (useCount) {
      setCount(value.length);
    }
    if (!useDefault && !hasMask) {
      event.target.value = value;
    }
  };

  const lengthRemaing = maxLength - count;
  const renderCount = useCount && (
    <Count>{lengthRemaing} caracteres restantes</Count>
  );

  useEffect(() => {
    if (defaultValue) {
      handleChange(defaultValue, true);
    }
  }, []);

  return (
    <ContainerLabelInput style={{ width: width }} removeMargin={removeMargin}>
      {label && (
          <UnInputLabel htmlFor={name} fontSize={fontSize}>
            {useHint && (
              <Tooltip {...hintConfig}>
                <HintInformation>i</HintInformation>
              </Tooltip>
            )}
            <strong>{label}</strong>
            {mandatoryLabel && (
              <span style={{ marginLeft: '8px' }}>
                (Obrigatório)
              </span>
            )}
          </UnInputLabel>
      )}
      <UnInput
        universityColor={universityColor}
        error={!!error}
        ref={inputRef}
        maxLength={maxLength}
        defaultValue={defaultValue}
        onFocus={clearError}
        onChange={(e) => handleChange(e, false)}
        style={{ padding: padding, height: height }}
        {...rest}
      />
      {onSearchIcon && (
        <IconSearchAddress>
          <AiOutlineSearch size={24} />
        </IconSearchAddress>
      )}
      {renderCount}
      {showErrorLabel && error && <Error>{error}</Error>}
    </ContainerLabelInput>
  );
}
