import React, { useCallback, useEffect, useState, Fragment } from 'react';
import { Form } from '@unform/web';
import {
  WrapperTitle,
  H3Title,
  WrapperPasswords,
  WrapperVerifyPassword,
  ContainsH5,
  ParagraphError,
  WrapperVerifications,
  Container,
} from '../style';
import * as Yup from 'yup';

import ErrorVector from '../../../../../assets/JobOffer/ErrorVector.svg';
import CheckedVector from '../../../../../assets/JobOffer/CheckedVector.svg';
import AuthContext from 'contexts/authentication';
import JobOfferPublicationTypeModal from 'views/JobOfferRebuild/Components/JobOfferForm/JobOfferPublicationTypeModal';

import UnformSelect from 'components/UnformFields/Select/SearchableSelect';
import { isStaffEmailExists } from 'services/companies';
import { getTermCompanyNotAuthenticate } from 'services/acceptanceTerm';
import Input from 'components/UnformFields/inputField';
import { handleFieldMask } from 'utils/validation';
import { maskInputPhone } from 'utils/inputMasks';
import InformationModal from 'components/Challenges/InformationModal';

export default function StepOneRegister(props) {
  const formRef = props.formRef;
  const [modalInformationIsOpen, setModalInformationIsOpen] = useState(false);
  const [grecaptcha, setGrecaptcha] = useState(null);

  const universityColor = props?.location?.state?.universityColor || '#009291';

  const { options } = props;

  const [securePassword, setSecurePassword] = useState({
    length: false,
    letters: false,
    specialCharacters: false,
    numbers: false,
    password: null,
  });

  const [verificatePassword, setVerificatePassword] = useState({
    password: null,
  });
  const [equalPassword, setEqualsPassword] = useState(false);
  const [disabledButton, setDisabledButton] = useState(true);

  const [errorEmailExist, setErrorEmailexist] = useState(true);

  const handleChange = (e) => {
    const password = e?.target?.value || e;
    const regexNumber = new RegExp(/^(?=.*[0-9]).+$/);
    const regexLetters = new RegExp(/^(?=.*[a-zA-Z]).+$/);
    const regexSpecialCharacters = new RegExp(
      /^(?=.*[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]).+$/
    );
    const length = password?.length >= 8;

    setSecurePassword({
      length,
      password,
      letters: regexLetters.test(password),
      specialCharacters: regexSpecialCharacters.test(password),
      numbers: regexNumber.test(password),
    });
  };

  const handleBlurEmail = async (event) => {
    props?.formRef?.current?.setErrors({});
    const value = event.target.value;
    const email = value.toLowerCase();

    if (email.length > 0) {
      const output = await isStaffEmailExists(email);
      const { exists } = output;

      if (exists) {
        return props?.formRef?.current?.setFieldError(
          'email',
          'E-mail já está em uso'
        );
      }
    }
  };

  async function validateField(field) {
    try {
      const data = props.formRef?.current?.getData();
      await schema.validateAt(field, data, {
        abortEarly: false,
      });
      const errors = props?.formRef?.current?.getErrors();
      delete errors[field];
      props?.formRef?.current?.setErrors(errors);
    } catch (err) {
      const validationErrors = props.formRef?.current?.getErrors();
      if (validationErrors && err instanceof Yup.ValidationError) {
        err.inner.forEach((error) => {
          validationErrors[error.path] = error.message;
        });
        props?.formRef?.current?.setErrors(validationErrors);
      }
    }
  }

  const handleVerifyPassword = (e) => {
    const password = e?.target?.value || e;
    setVerificatePassword({ password });
  };

  const existPasswordValues = () => {
    return (
      verificatePassword?.password?.length > 0 &&
      securePassword?.password?.length > 0
    );
  };

  const passwordPassed = () => {
    if (
      securePassword.length &&
      securePassword.letters &&
      securePassword.numbers &&
      securePassword.specialCharacters &&
      !errorEmailExist
    ) {
      setDisabledButton(false);
    }
  };

  useEffect(() => {
    if (existPasswordValues()) {
      if (verificatePassword.password === securePassword.password) {
        passwordPassed();
        setEqualsPassword(true);
        return;
      }

      setEqualsPassword(false);
    }
  }, [securePassword, verificatePassword, errorEmailExist]);

  useEffect(() => {
    props.setPasswordsMatch(equalPassword);
  }, [equalPassword]);

  useEffect(() => {
    if (props.objectSteps) {
      formRef?.current?.setFieldValue(
        'staff_role',
        props.objectSteps.staff_role
      );
      formRef?.current?.setFieldValue(
        'staff_sector',
        props.objectSteps.staff_sector
      );
    }
  }, [props.objectSteps, options]);

  useEffect(
    () => () => {
      window.removeEventListener('loadGrecaptcha');
    },
    []
  );

  useEffect(() => renderCaptcha(), [grecaptcha]);

  useEffect(() => {
    if (grecaptcha == null) {
      window.addEventListener('loadGrecaptcha', (event) => {
        setGrecaptcha(event.detail);
      });
    }
  }, []);

  const renderCaptcha = useCallback(() => {
    if (grecaptcha == null) return;
    window.removeEventListener('loadGrecaptcha');
    try {
      grecaptcha.render('captchaDiv', {
        sitekey: captcha.siteKey,
        callback: (token) => verifyCaptcha(token),
      });
      setTimeout(() => setLoadedCaptcha(true), 500);
    } catch (e) {
      return;
    }
  }, [grecaptcha]);

  return (
    <Fragment>
      <JobOfferPublicationTypeModal
        openModal={modalInformationIsOpen}
        setOpenModal={setModalInformationIsOpen}
      />

      <AuthContext.Consumer>
        {({ routerOnUserLogin }) => {
          return (
            <Container>
              <InformationModal
                modalOpenState={modalInformationIsOpen}
                config={{
                  bubbleText: 'Parabéns!',
                  ParagraphText: `Seu cadastro foi realizado com sucesso!
                   Deseja cadastrar uma vaga para o nosso banco de talentos?`,
                  buttonConfirmText: 'SIM, CADASTRAR VAGA',
                  showButtonCancel: true,
                  buttonCancelText: 'NÃO',
                }}
                buttonConfirmFunction={() => setOpenJobOfferModal(true)}
                buttonCancelFunction={() => {
                  setModalInformationIsOpen(false);
                  routerOnUserLogin();
                  window.location.reload();
                }}
              />
              <WrapperTitle>
                <H3Title universityColor={universityColor}>
                  Dados de acesso
                </H3Title>
              </WrapperTitle>
              <Form ref={formRef} initialData={props.objectSteps}>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '0.5em',
                  }}
                >
                  <Input
                    universityColor={universityColor}
                    name="staff_name"
                    label="Responsável pelo cadastro*"
                    mandatoryLabel={true}
                    placeholder="Digite aqui..."
                  />

                  <Input
                    name="email"
                    label="E-mail*"
                    mandatoryLabel={true}
                    onBlur={handleBlurEmail}
                    universityColor={universityColor}
                    placeholder="Digite aqui..."
                  />
                  <Input
                    universityColor={universityColor}
                    name="phone"
                    label="Telefone*"
                    mandatoryLabel={true}
                    placeholder="Digite aqui..."
                    onChange={() => validateField('phone')}
                    onKeyUp={(e) => {
                      handleFieldMask(e, maskInputPhone);
                    }}
                  />
                  <UnformSelect
                    name="staff_role"
                    options={options.roles || []}
                    label="Cargo*"
                    mandatoryLabel={true}
                    placeholder="Selecione"
                    universityColor={universityColor}
                    labelWhite={true}
                    margin={'5px 0 -10px 0'}
                  />
                  <UnformSelect
                    name="staff_sector"
                    options={options.sectors || []}
                    label="Setor*"
                    mandatoryLabel={true}
                    placeholder="Selecione"
                    universityColor={universityColor}
                    margin={'5px 0 0 0'}
                    labelWhite={true}
                  />
                </div>

                <WrapperPasswords>
                  <Input
                    name="staff_password"
                    label="Senha*"
                    mandatoryLabel={true}
                    type="password"
                    placeholder="••••••••••"
                    autocomplete="new-password"
                    onChange={handleChange}
                    universityColor={universityColor}
                  />
                  <Input
                    label="Confirme sua senha*"
                    mandatoryLabel={true}
                    type="password"
                    name="staff_password_confirmation"
                    placeholder="••••••••••"
                    onChange={handleVerifyPassword}
                    universityColor={universityColor}
                  />
                </WrapperPasswords>

                {existPasswordValues() && !equalPassword && (
                  <ParagraphError>Senhas não correspondem</ParagraphError>
                )}

                <WrapperVerifyPassword>
                  <ContainsH5 universityColor={universityColor}>
                    Sua senha deve conter:
                  </ContainsH5>

                  <WrapperVerifications>
                    <div>
                      <img
                        src={
                          securePassword.length ? CheckedVector : ErrorVector
                        }
                      />
                      <p>Mínimo de 8 caracteres</p>
                    </div>

                    <div>
                      <img
                        src={
                          securePassword.letters ? CheckedVector : ErrorVector
                        }
                      />
                      <p>Letras</p>
                    </div>

                    <div>
                      <img
                        src={
                          securePassword.specialCharacters
                            ? CheckedVector
                            : ErrorVector
                        }
                      />
                      <p>Caracteres especiais</p>
                    </div>

                    <div>
                      <img
                        src={
                          securePassword.numbers ? CheckedVector : ErrorVector
                        }
                      />
                      <p>Números</p>
                    </div>
                  </WrapperVerifications>
                </WrapperVerifyPassword>
              </Form>
            </Container>
          );
        }}
      </AuthContext.Consumer>
    </Fragment>
  );
}
